const Fields = [
    {
        name: "dst_sandbox_name",
        label: "Destination Sandbox Name",
        type: "select",
        editable: true,
        validation: [
            { type: "required", errMsg: "Dst Sandbox Name  Field is required" }
        ]
    },
    {
        name: "dst_job_name",
        label: "Destination Job Name",
        type: "customSingleSelect",
        editable: true,
        validation: [
            { type: "required", errMsg: "Dst Job Name  Field is required" }
        ],
        placeholder: "Enter destination job name"
    },
    {
        name: "description",
        label: "Description",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Description  Field is required" }
        ]
    },
    {
        name: "src_job_run_id",
        label: "Job Run Id",
        type: "customSingleSelect",
        placeholder: "Enter a job run ID, or select one from the list.",
        editable: true,
       
    },
    {
        name: "schedule",
        label: "Override Schedule",
        type: "cron",
        editable: true,       
    },
    {
        name: "vars",
        label: "Override Vars",
        type: "yaml",
        editable: true,
    },
    {
        name: "detach_meters",
        label: "Detach Meters",
        type: "checkbox",
        editable: true,
    }

];
export default Fields;
