<template>
    <Datatable 
        :tableId="tableId" 
        :columns="columns"
        :data="data"
        :showLoading="loading"
        uniqueField = "name"
        :orderColumn="orderColumn"
        :formatColumn="formatColumn"
        :selectCheckboxes="true"
        v-on:selected="handleSelect"
        :compact="true" 
        @rowCallback="findFileorFolder"
        
    />
</template>
<script>
import Datatable from "./Datatable.vue";
import { mapActions } from "vuex";
export default{
    name: 'FolderView',
    components: { Datatable },
    data(){
        return{
            columns: [
                {
                    data: "",
                    title: "",
                    createdCell : (td, cellData, rowData, row, col) => {
                        let self = this;
                        let b = document.createElement('button');
                        b.classList.add('btn','btn-link');

                        var icon = document.createElement('i');
                        icon.classList.add('fa', 'fa-folder');
                        b.appendChild(icon);

                        b.addEventListener('click', function() {
                            console.log(cellData);
                            console.log(rowData);
                            console.log(self.identifier);
                            console.log(rowData[self.identifier]);
                            console.log(self.identifier? (rowData[self.identifier]!=""?rowData[self.identifier]:"/"): rowData.name)
                            self.$store.dispatch("setBreadcrumbs",[...self.$store.state.breadcrumbs,{
                                name:  self.identifier? (rowData[self.identifier]!=""?rowData[self.identifier]:"/"): rowData.name, //to handle / folder name
                                route:  ""
                            }])
                            self.$emit('fetchSubFolder', rowData[self.identifier]);
                        });
                        td.innerHTML = '';
                        td.appendChild(b);
                    }

                },
                {
                    data: "name",
                    title: "name",
                },
                {
                    data: "size",
                    title: "Size",
                },
                {
                    data: "time",
                    title: "time",
                },
            ],
            formatColumn: [
                {
                    index: 3,
                    type: 'bytes'
                },
                {
                    index: 4,
                    type: "datetime"
                }
            ],
            orderColumn: [4, "desc"],
        }
    },
    props: {
        data: {
            type: Array,
            default: function(){
                return []
            }
        },
        baseUrl:{
            type: String,
            default:""
        },
        name:{
            type: String,
            default: ""
        },
        identifier:{
            type: String,
            default: ""
        },
        icon: String,
        loading : {
            type: Boolean,
            default : false,
        },
        tableId:{
            type: String,
            default :"fileBrowser"
        }
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
        }),
        findFileorFolder(row, rowData, type, index) {
            if (rowData) {
                if (rowData.is_dir=="false") {
                    // for files show file icon
                    row.childNodes[1].childNodes[0].childNodes[0].className="detail-icon fa fa-regular fa-file"
                } else {
                    // Hide select row for folder
                    row.children[0].disabled = true;
                    row.children[0].classList.remove('select-row', 'fa', 'fa-regular', 'select-checkbox-fa');
                        
                }
            }
        },
        handleSelect(row) {
            console.log(row);
            this.$emit('selectedFiles', row);
        }        
    },
    emits: ['fetchSubFolder', 'selectedFiles']
}
</script>
<style>

</style>