<template>
    <div class="row">
        <div class="col-md-4">
            <input-field label="Start Date" type="calendar" v-model="startDate" :inputConfig="config" />
        </div>
        <div class="col-md-4">
            <input-field label="End Date" type="calendar" v-model="endDate" :inputConfig="config" />
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label class="form-label">&nbsp;</label>
                <div class="text-end">
                    <button v-if="isAdmin" class="btn btn-primary" @click="getReports">
                        Generate Report</button
                    ><span>&nbsp;</span>
                    <button class="btn btn-primary" @click="exportCSV">
                        Export CSV
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <datatable
                    tableId="sigincredentials"
                    :columns="columns"
                    uniqueField="email"
                    :formatColumn="formatColumn"
                    :data="signIns"
                    :info="false"
                    :compact="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import csv from "../../../utils/tools/csv"
import dayjs from "dayjs";
import InputField from "../../../components/InputField.vue";
import Datatable from "../../../components/Datatable.vue";
export default {
    components: { InputField, Datatable },
    data() {
        return {
            config: {
                wrap: true, 
                dateFormat: 'm-d-Y',
            },
            startDate: dayjs().subtract(7, "day").format("MM/DD/YYYY"),
            endDate: dayjs().format("MM/DD/YYYY"),
            signIns: null,
            columns: [
                {
                    data: "email",
                    title: "User",
                },
                {
                    data: "sign_in_time",
                    title: "Sign in",
                }
            ],
            formatColumn:[
                {
                    index:1,
                    type:"datetime"
                }
            ],
        };
    },
    methods: {
        getReports() {
            var since = dayjs(this.startDate, 'MM-DD-YYYY').format("YYYYMMDDHHmmss");
            var until = dayjs(this.endDate, 'MM-DD-YYYY').format("YYYYMMDDHHmmss");
            this.signIns = null

            return axios
                .get(
                    `${this.$store.state.authUrl}/v1/sign-ins?cid=${this.$store.state.currentClient.cid}&since=${since}&until=${until}&method=OAUTH2&all=true`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.data) {
                        this.signIns = response.data;
                    }
                });
        },
        exportCSV() {
            csv.exportCSV("sign-ins", this.signIns)
        }
    },
    computed: {
        isAdmin() {
            return this.$store.getters.userRoles.isAqferAdmin;
        }
    },
    mounted () {
        this.getReports()
    }
};
</script>

<style>
</style>