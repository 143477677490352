<template>
    <div class="card">
        <form @submit.prevent="onCreateJobTemplateGroup">
            <div class="card-body">
                <FormFields :fields="fields" v-model="jobTemplateGroup" />
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <SubmitButton :fields="fields" v-model="jobTemplateGroup" :loading="loading" />
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import FormFields from "../../components/FormFields.vue";
import SubmitButton from "../../components/SubmitButton.vue";
import { mapActions } from "vuex";
import Fields from "../../utils/field-constants/jobTemplateGroupFields";

export default {
    components: { FormFields, SubmitButton },
    data() {
        return {
            error: "",
            jobTemplateGroup: {},
            loading: false,
            defaultFile: "",
            requiredFiles: [],
            fields: Fields
        };
    },
    name: "AddJobTemplateGroup",
    watch: {
        "jobTemplateGroup.is_pipeline": {
            handler: function (newVal) {
                this.fields = this.fields.map((field) => {
                    if (field.name == "pipeline_config") {
                        field.visible = newVal ? newVal : false
                        if (field.visible)
                            this.jobTemplateGroup["pipeline_config"] = [{ relative_path: "config.yaml", content: "" }]
                        else
                            this.jobTemplateGroup["pipeline_config"] = ""
                    }
                    return field
                })
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions({
            createJobTemplateGroup: "admin/createJobTemplateGroup",
        }),
        async onCreateJobTemplateGroup() {
            this.loading = true;
            let response = {};
            try {
                let data = JSON.parse(JSON.stringify(this.jobTemplateGroup))
                data.pipeline_config = JSON.stringify(data.pipeline_config)
                data.tag = data.tag ? data.tag.toString() : ""
                response = await this.createJobTemplateGroup(data);
            } catch (error) {
                this.loading = false;
                if (error.response.data.error) this.error = error.response.data.error;
                else this.error = error.message;

                return;
            }
            this.loading = false;
            if (response.data) {
                this.$router.push({
                    name: "Job Template Group",
                    params: {
                        id: `${response.data.id}`,
                    },
                });
            }
        }
    },
};
</script>

<style></style>