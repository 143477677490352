<template>
    <div class="modal-card card">
        <form @submit.prevent="(modalData.refresh)?onRefreshSandboxCredential():onAddSandboxCredential()">
            <div class="card-header">
                <h4 v-if="modalData.refresh" class="card-header-title">
                    Refreshing Sandbox Credentials
                </h4>
                <h4 v-else class="card-header-title">
                    Attach Sandbox Credential
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body cred" v-if="modalData.refresh">
                <p>Updating  Credentials : 
                    <span ><strong>{{ selectedCredential.toString() }}</strong></span>    
                </p>                
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
            </div>
            <div class="card-body cred" v-else>
                <InputField label="Choose a Sandbox" type="select" :options="sandboxOptions" v-model="selectedSandbox" />
                <InputField label="Choose Credentials" type="multiselect" :options="credentialOptions" :createOption="false"
                    v-model="selectedCredential" />
                    <div v-if="selectedCredential.length > 0">
                        <p>Enter Sandbox Credential Name <span class="text-danger">&nbsp;*</span></p>
                        <FormFields
                            :fields="aliasFields"
                            v-model="aliasValue"
                            :refreshOn="refreshOn"
                            :inlineField="true" />
                    </div>
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <button class="btn btn-primary" :disabled="buttonState">
                        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else>
                            <i v-if="buttonState" class="fa fa-circle-exclamation"
                                title="Please enter all the required fields" data-bs-toggle="tooltip"
                                data-bs-placement="left"></i>
                           Submit
                        </div>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import InputField from '../InputField.vue';
import FormFields from '../FormFields.vue';
export default {
    name: "AddSandboxCredentials",
    components: { InputField, FormFields },
    data() {
        return {
            selectedCredential: this.modalData.sandboxCredList,
            selectedSandbox: this.modalData.selectedSandbox,
            loading: false,
            error: null,
            snapshot: {},
            aliasValue: this.modalData.sandboxCredListAlias,
            refreshOn: false,
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    mounted(){
        if (this.aliasValue)
            this.snapshot=JSON.parse(JSON.stringify(this.aliasValue))
    },
    watch:{
        selectedSandbox(){
            this.selectedCredential=[];
            this.snapshot = {};
            this.aliasValue={};
        },
        selectedCredential(){
            let snapAliasValue= this.aliasValue
            this.aliasValue={};
            this.selectedCredential.forEach(cred => snapAliasValue[cred]? this.aliasValue[cred]= snapAliasValue[cred]: this.aliasValue[cred]=cred)
        }
    },
    computed: {
        sandboxOptions() {
            return this.modalData ? this.modalData.sandboxOptions : []
        },
        credentialOptions() {
            return this.$store.state.amdp.credentials.map((cred) => {
                return cred.name
            })
        },
        buttonState(){
            return !(this.selectedSandbox && (this.selectedCredential && this.selectedCredential.length>0))
        },
        staleCreds() {
            const self = this;
            const staleCreds = this.selectedCredential.filter((sandboxCred) => {
                if(!self.credentialOptions.find((cred) => sandboxCred == cred)) {
                    return sandboxCred;
                }
            });
            return staleCreds;
        },
        aliasFields(){
            return this.selectedCredential.map((cred)=>{
                return {
                    name: cred,
                    label: cred,
                    placeholder:"Sandbox Credential Name",
                    type: "text",
                    editable: true
                }
            })
        }
    },
    methods: {
        ...mapActions({
            fetchCredentials: "amdp/fetchCredentials",
            upsertSandboxCredential: "amdp/upsertSandboxCredential",
            getSandboxCredentials: "amdp/getSandboxCredentials"
        }),
        async onRefreshSandboxCredential() {
            const self = this;
            let creds= this.selectedCredential;
            await this.updateSandboxCredentail([], creds, false ); //Remove first
            creds=[];
            Object.entries(this.snapshot).forEach(([key, value]) => {
                creds.push({"client_credential_name": key, "sandbox_credential_name": value})
            })
            await this.updateSandboxCredentail(creds ,[], true); //Add next
        },

        onAddSandboxCredential() {
            //to find newly added credential or alias changed credential
            let addCred = Object.entries(this.aliasValue).reduce((newObj, [key, val]) => {
                            if ((val !==this.snapshot[key])) {
                                newObj[key] = val;
                            }
                            return newObj;
                            }, {})
            let add =[];
            Object.entries(addCred).forEach(([key, value]) => {
                add.push({"client_credential_name": key, "sandbox_credential_name": value})
            })
            //to find newly removed credential
            let remove = Object.keys(this.snapshot).filter(cred => !this.selectedCredential.some(snap => cred === snap));
            this.updateSandboxCredentail(add, remove, true);

        },
        async updateSandboxCredentail(add, remove, flag) {
            let toastoptions;
            this.loading= true;
            try {               
                //Check whether add credential and remove credential list contain value
                if( add.length>0 || remove.length>0){
                    let emptyAlias=add.filter((cred)=>cred.sandbox_credential_name=="")
                    if(!emptyAlias.length){
                        let payload = { "add_credential_mappings": add, "remove_credentials": remove }
                        await this.upsertSandboxCredential({ sandboxName: this.selectedSandbox, payload })
                        toastoptions = {
                            message: "Sandbox Credentials updated successfully.",
                            type: "success"
                        }
                    }else{
                        flag=false;
                        toastoptions = {
                            message: "Sandbox Credentials name is empty. Please update and submit",
                            type: "error"
                        }
                    }
                }else{
                    flag=false;
                    toastoptions = {
                            message: "Sandbox Credentials are not changed. Please update and submit",
                            type: "warning"
                    }
                }
            } catch (error) {
                if (error.response)
                        this.error = error.response.data.error;
                else this.error = error.message;
                toastoptions = {
                    message: this.error || "Something went wrong when adding sandbox credential. Please contact support.",
                    type: "error"
                }
            }
            this.loading= false;
            this.$store.dispatch('addToast', toastoptions);
            //If there is no change in original value, then show alert and stay in modal
            if(flag) {           
                this.$store.dispatch("sandbox/setCurrentSandbox", this.selectedSandbox);
                this.$store.dispatch('hideModal');
                this.getSandboxCredentials(this.selectedSandbox);
            }
        }

    }
}

</script>
<style>
.cred {
    overflow-y: inherit !important;
}
</style>
