<template>
    <div class="modal-card card">
        <form @submit.prevent="handleSubmit">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Detach Meters</h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <p>
                    Are you sure you want to detach meters for
                    <b>{{ jobDetails.jobName }}</b>?
                </p>
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">
                    <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else>
                        Detach
                    </div>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "Detach Meters",
    data() {
        return {
            error: "",
            jobDetails: this.modalData.jobDetails,
            loading: false,
        };
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    methods: {
        async handleSubmit() {
            this.loading = true;
            let cid = this.jobDetails.cid
            let sandbox = this.jobDetails.sandboxName
            let job = this.jobDetails.jobName
            axios.delete(`${this.$store.state.lakeviewUrl}/v1/cids/${cid}/sandboxes/${sandbox}/jobs/${job}/meters`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                this.$store.dispatch('hideModal');
            }).catch((error) => {
                if (error.response.data.error)
                    this.error = error.response.data.error
                else
                    this.error = error.message
                return
            }).finally(() => {
                this.loading = false;
            })
        },
    },
};
</script>

<style></style>