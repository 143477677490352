//router
import { createRouter, createWebHistory } from "vue-router";
import store from '../store'
import axios from "axios";

//Main Sections
import Portal from '../views/Portal'
import Login from '../views/Login'
import Logout from '../views/Logout'
import Dashboard from '../views/Dashboard'
import ChangeLog from '../views/ChangeLog'

//Admin Section
import Users from '../views/admin/Users'
import User from '../views/admin/User'
import Clients from '../views/admin/Clients'
import Client from '../views/admin/Client'
import Usage from '../views/admin/Usage'
import UsageTest from '../views/admin/UsageTest'
import UsageDemo from '../views/admin/UsageDemo'
import InfrastructureUsage from '../views/admin/InfrastructureUsage'
import Reports from '../views/admin/reports/Reports'
import SignIns from "../views/admin/reports/SignIns.vue";
import AdminDashboards from '../views/admin/Dashboards'
import Tokens from '../views/admin/Tokens';
import Token from '../views/admin/Token';
import EncryptedURLs from '../views/admin/EncryptedURLs';
import EncryptedURL from '../views/admin/EncryptedURL';
import Products from '../views/admin/Products';
import Product from '../views/admin/Product';
import Entitlements from '../views/admin/Entitlements';
import Entitlement from '../views/admin/Entitlement';
import JobTemplate from '../views/admin/JobTemplate';
import JobTemplates from '../views/admin/JobTemplates';
import JobTemplateDetails from '../views/admin/JobTemplateDetails';
import JobTemplateGroups from '../views/admin/JobTemplateGroups';
import JobTemplateGroup from '../views/admin/JobTemplateGroup';
import AddJobTemplateGroup from "../views/admin/AddJobTemplateGroup.vue";

//aUT
import GeneralSettings from '../views/aut/Settings'
import Classes from '../views/aut/Classes'
import TagTemplates from '../views/aut/TagTemplates'
import TagTemplate from '../views/aut/TagTemplate'
import PublicTagTemplates from '../views/aut/PublicTagTemplates'
import PublicTagTemplate from '../views/aut/PublicTagTemplate'
import InitiatorFields from '../views/aut/InitiatorFields'
import InitiatorField from '../views/aut/InitiatorField'
import GenerateTag from '../views/aut/GenerateTag'
import ConfigureHosts from '../views/aut/ConfigureHosts'
import ConfigureCnames from '../views/aut/ConfigureCNames'
import AutReports from '../views/aut/Reports'
import AutDashboards from '../views/aut/Dashboards'
import CreateTagTemplate from '../views/aut/CreateTagTemplate'
import Initiators from '../views/aut/Initiators'
import Initiator from '../views/aut/Initiator'
import autUsage from '../views/aut/Usage'

//aMDP
import Queries from '../views/amdp/Queries'
import Query from '../views/amdp/Query'
import Credentials from '../views/amdp/Credentials'
import Credential from '../views/amdp/Credential'
import amdpUsage from '../views/amdp/Usage'
import JobsDashboard from '../views/amdp/JobsDashboard'
import Jobs from '../views/amdp/job-management/Jobs'
import Pipelines from '../views/amdp/job-management/Pipelines'
import PipelineDetail from '../views/amdp/job-management/PipelineDetails'
import JobsTab from '../views/amdp/JobsTab'
import JobDetail from '../views/amdp/JobDetail'
import AdminQueries from '../views/amdp/AdminQueries'
import FileBrowser from '../views/filebrowser/Browser'
import FolderView from '../views/filebrowser/FolderView'
import FileView from '../views/filebrowser/FileView'
import OutputParser from '../components/OutputParser';
import Configurator from '../views/amdp/Configurator';
import ConfiguratorDetails from '../views/amdp/ConfiguratorDetails';

//aGM
import agmDashboards from '../views/agm/Dashboards'
import IDRList from '../views/agm/IDRList'
import IDRJobDetail from '../views/agm/IDRJobDetail'
import IDRConfiguration from '../views/agm/job-management/Configuration'
import IDRVisualizer from '../views/agm/SingleNodeVisualizer'

//aIO
import aioUsage from '../views/aio/Usage'
import aioDashboards from '../views/aio/Dashboards'
import aioHandlerSandbox from '../views/aio/HandlerSandbox'
import HandlerDetails from '../views/aio/HandlerDetails'
import HandlerConfiguration from '../views/aio/handler-management/Configuration'
import HandlerRequests from '../views/aio/handler-management/Requests'

//Sandbox
import UnitTest from '../views/sandbox/UnitTest'
import UnitTestExecution from '../views/sandbox/UnitTestExecution'
import Management from '../views/sandbox/Management'
import SandboxUserAccess from '../views/sandbox/SandboxUserAccess'
import SandboxDetail from '../views/sandbox/SandboxDetail'
import SandboxJobList from '../views/sandbox/SandboxJobList'
import SandboxJobDetail from '../views/sandbox/SandboxJobDetail'
import SandboxCredentials from '../views/sandbox/SandboxCredentials'
import SandboxCredential from '../views/sandbox/SandboxCredential'

import Configuration from '../views/sandbox/job-management/Configuration'
import RunHistory from '../views/sandbox/job-management/RunHistory'
import ExecutionDetails from '../views/sandbox/job-management/ExecutionDetails'
import SandboxJobPromotions from '../views/sandbox/job-management/Promotions'
import JobOverride from '../views/sandbox/job-management/Override'
import JobMeters from '../views/sandbox/job-management/Meters'
import Promotions from '../views/sandbox/Promotions'
import PromotionDetail from '../views/sandbox/PromotionDetail'
import PromotionConfiguration from '../views/sandbox/promotion-management/Configuration'
import PromotionRunHistory from '../views/sandbox/promotion-management/RunHistory' 
import PromotionOverview from '../views/sandbox/promotion-management/Overview'

//Apps
import ClientApp from '../views/ClientApp.vue'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '',
        component: Portal,
        children: [
            {
                path: '',
                name: 'Home',
                component: Dashboard
            },
            {
                path: '/changelog',
                name: 'ChangeLog',
                component: ChangeLog
            },
            {
                path: '/admin/users',
                name: 'Users',
                component: Users
            },
            {
                path: '/admin/users/:id',
                name: 'User',
                component: User
            },
            {
                path: '/admin/clients',
                name: 'Clients',
                component: Clients
            },
            {
                path: '/admin/usage',
                name: 'Entitlement Usage (beta)',
                component: Usage
            },
            {
                path: '/admin/usagetest',
                name: 'Entitlement Usage TESTING',
                component: UsageTest
            },
            {
                path: '/admin/usagedemo',
                name: 'Entitlement Usage Mockup',
                component: UsageDemo
            },
            {
                path: '/admin/infrastructure-usage',
                name: 'Infrastructure Usage',
                component: InfrastructureUsage
            },
            {
                path: '/admin/clients/:id',
                alias: '/admin/clients/:id/subscriptions',
                name: 'Client',
                component: Client
            },
            {
                path: '/admin/reports',
                name: 'Reports',
                component: Reports,
                children: [
                    {
                        path: '/admin/reports/sign-ins',
                        name: 'Sign Ins',
                        component: SignIns
                    },
                ]
            },
            {
                path: '/admin/dashboards',
                name: 'Dashboards',
                component: AdminDashboards
            },
            {
                path: '/admin/tokens',
                name: 'Tokens',
                component: Tokens
            },
            {
                path: '/admin/tokens/:id',
                name: 'Token',
                component: Token,
                meta: {
                    "cidChangeRedirectRoute": "Tokens"
                }
            },
            {
                path: '/admin/encrypted-urls',
                name: 'EncryptedURLs',
                component: EncryptedURLs
            },
            {
                path: '/admin/encrypted-urls/:id',
                name: 'EncryptedURL',
                component: EncryptedURL
            },
            {
                path: '/admin/products',
                name: 'Products',
                component: Products
            },
            {
                path: '/admin/products/:id',
                name: 'Product',
                component: Product
            },
            {
                path: '/admin/entitlements',
                name: 'Entitlements',
                component: Entitlements
            },
            {
                path: '/admin/entitlements/:id',
                name: 'Entitlement',
                component: Entitlement
            },
            {
                path: '/admin/job-templates',
                name: 'Job Template Detail',
                component: JobTemplateDetails,
                children: [
                    {
                        path: '/admin/job-templates',
                        name: 'Job Templates',
                        component: JobTemplates
                    },
                    {
                        path: '/admin/job-templates/:id',
                        name: 'Job Template',
                        component: JobTemplate
                    },
                    {
                        path: '/admin/job-template-groups',
                        name: 'Job Template Groups',
                        component: JobTemplateGroups
                    }
                    ,
                    {
                        path: '/admin/job-template-groups/:id',
                        name: 'Job Template Group',
                        component: JobTemplateGroup
                    },
                    {
                        path: '/admin/add-job-template-groups',
                        name: 'Add Job Template Group',
                        component: AddJobTemplateGroup
                    }
                ]

            },
            {
                path: '/:cid/aut/settings',
                name: 'Settings',
                component: GeneralSettings,
                alias: '/aut/settings'
            },
            {
                path: '/:cid/aut/classes',
                name: 'Classes',
                component: Classes,
                alias: '/aut/classes'
                
            },
{
                path: '/:cid/aut/tag-templates',
                name: 'Tag Template Library',
                component: TagTemplates,
                alias: '/aut/tag-templates',
                
            },
            {
                path: '/:cid/aut/tag-templates/:id',
                name: 'Tag Template',
                component: TagTemplate,
                alias: '/aut/tag-templates/:id',
                meta: {
                    "cidChangeRedirectRoute": "Tag Template Library"
                }
            },
            {
                path: '/:cid/aut/browse-tag-templates',
                name: 'Browse Tag Templates',
                component: PublicTagTemplates,
                alias: '/aut/browse-tag-templates',
            },
            {
                path: '/:cid/aut/browse-tag-templates/:id',
                name: 'Public Tag Template',
                component: PublicTagTemplate,
                alias: '/aut/browse-tag-templates/:id',
            },
            {
                path: '/:cid/aut/initiators',
                name: 'Initiators',
                component: Initiators,
                alias: '/aut/initiators'
            },
            {
                path: '/:cid/aut/initiators/:id',
                name: 'Initiator',
                component: Initiator,
                alias: '/aut/initiators/:id',
                meta: {
                    "cidChangeRedirectRoute": "Initiators"
                }
                
            },
            {
                path: '/:cid/aut/initiator-fields',
                name: 'Initiator Fields',
                component: InitiatorFields,
                alias: '/aut/initiator-fields'
            },
            {
                path: '/:cid/aut/initiator-fields/:id',
                name: 'Initiator Field',
                component: InitiatorField,
                alias: '/aut/initiator-fields/:id',
                meta: {
                    "cidChangeRedirectRoute": "Initiator Fields"
                }
            },
            {
                path: '/:cid/aut/generate-tag',
                name: 'Generate Tag',
                component: GenerateTag,
                alias: '/aut/generate-tag'
            },
            {
                path: '/:cid/aut/configure-hosts',
                name: 'Configure Hosts',
                component: ConfigureHosts,
                alias: '/aut/configure-hosts'

            },
            {
                path: '/:cid/aut/configure-hosts/:hostName',
                name: 'Configure CNames',
                component: ConfigureCnames,
                alias: '/aut/configure-hosts/:hostName',
                meta: {
                    "cidChangeRedirectRoute": "Configure Hosts"
                }

            },
            {
                path: '/:cid/aut/create-tag-template',
                name: 'Create Tag Template',
                component: CreateTagTemplate,
                alias: '/aut/create-tag-template',

            },
            {
                path: '/:cid/aut/reports',
                name: 'Tag Reports',
                component: AutReports,
                alias: '/aut/reports' 
            },
            {
                path: '/:cid/aut/dashboards',
                name: 'Tag Dashboards',
                component: AutDashboards,
                alias: '/aut/dashboards'
            },
            {
                path: '/:cid/aut/usage',
                name: 'Usage',
                component: autUsage,
                alias: '/aut/usage'
            },
            {
                path: '/:cid/amdp/queries',
                name: 'Named Queries',
                component: Queries,
                alias: '/amdp/queries'
            },
            {
                path: '/:cid/amdp/queries/:id',
                name: 'Named Query',
                component: Query,
                alias: '/amdp/queries/:id',
                meta: {
                    "cidChangeRedirectRoute": "Named Queries"
                }
            },
            {
                path: '/:cid/amdp/admin-queries',
                name: 'Admin Named Query',
                component: AdminQueries,
                alias: '/amdp/admin-queries'
            },
            {
                path: '/:cid/amdp/credentials',
                name: 'Credentials',
                component: Credentials,
                alias: '/amdp/credentials'
            },
            {
                path: '/:cid/amdp/credentials/:id',
                name: 'Credential',
                component: Credential,
                alias: '/amdp/credentials/:id',
                meta: {
                    "cidChangeRedirectRoute": "Credentials"
                }
            },
            {
                path: '/:cid/amdp/usage',
                name: 'aMDP Usage',
                component: amdpUsage,
                alias: '/amdp/usage',
            },
            {
                path: '/:cid/amdp/jobs-dashboard',
                name: 'Jobs Dashboard',
                component: JobsDashboard,
                alias: '/amdp/jobs-dashboard'
            },
            //Common File preview page, have path and limit value in query string
            {
                path: '/:cid/amdp/output-parser',
                name: 'Output Parser',
                component: OutputParser,
                alias: '/amdp/output-parser'
            },
            {
                path: '/:cid/amdp/:sandboxId/jobs',
                name: 'JobsTab',
                component: JobsTab,
                meta: {
                    "sandboxTypes": ["prod","prodV2"]
                },
                children: [
                    {
                        path: '/:cid/amdp/:sandboxId/jobs/list',
                        name: 'Jobs',
                        component: Jobs
                    },
                    {
                        path:'/:cid/amdp/:sandboxId/jobs/pipelines',
                        name: 'Pipelines',
                        component: Pipelines
                    },{
                        path:'/:cid/amdp/:sandboxId/jobs/pipelines/:pipelineName',
                        name: 'Pipeline Details',
                        component: PipelineDetail
                    }
                ]
            },            
            {
                path: '/:cid/amdp/:sandboxId/jobs/:jobId',
                name: 'Job Detail',
                component: JobDetail,
                meta: {
                    "sandboxTypes": ["prod","prodV2"],
                    "cidChangeRedirectRoute": "Jobs"
                },
                children: [
                    {
                        path: '/:cid/amdp/:sandboxId/jobs/:jobId/configuration',
                        name: 'Job Configuration',
                        component: Configuration,
                        alias: '/amdp/:sandboxId/jobs/:jobId/configuration'
                    }, {
                        path: '/:cid/amdp/:sandboxId/jobs/:jobId/run-history',
                        name: 'Job Run History',
                        component: RunHistory,
                        alias: '/amdp/:sandboxId/jobs/:jobId/run-history'
                    }, {
                        path: '/:cid/amdp/:sandboxId/jobs/:jobId/execution-details/:executionId',
                        name: 'Job Execution Details',
                        component: ExecutionDetails,
                        alias: '/amdp/:sandboxId/jobs/:jobId/execution-details/:executionId'
                    },  {
                        path: '/:cid/amdp/:sandboxId/jobs/:jobId/promotions',
                        name: 'Job Promotions',
                        component: SandboxJobPromotions,
                        alias: '/amdp/:sandboxId/jobs/:jobId/promotions'
                    },
                    {
                        path: '/:cid/amdp/:sandboxId/jobs/:jobId/override',
                        name: 'Job Override',
                        component: JobOverride
                    },
                    {
                        path: '/:cid/amdp/:sandboxId/jobs/:jobId/meters',
                        name: 'Job Meters',
                        component: JobMeters
                    }
                ]
            },
            {
                path: '/:cid/amdp/:sandboxId/file-browser',
                name: 'File Browser',
                component: FileBrowser,
                meta: {
                    "sandboxTypes": ["dev"]
                },
                children:[
                    {
                        path: '/:cid/amdp/:sandboxId/file-browser/:bucket/list',
                        name: 'File Browser List',
                        component: FolderView,
                        alias: '/amdp/:sandboxId/file-browser/:bucket/list'
                    },
                    {
                            path: '/:cid/amdp/:sandboxId/file-browser/:bucket/folder/:id',
                            name: 'File Browser Folder',
                            component: FolderView,
                            alias: '/amdp/:sandboxId/file-browser/:bucket/folder/:id'
                    },
                    {
                                path: '/:cid/amdp/:sandboxId/file-browser/:bucket/file/:id',
                                name: 'File Browser View',
                                component: FileView,
                                alias: '/amdp/:sandboxId/file-browser/:bucket/file/:id'
                    }    
                ]
            },
            {
                path: '/amdp/sandbox/unit-test',
                name: 'Unit Test',
                component: UnitTest,
                alias: '/amdp/sandbox/unit-test'
            },
            {
                path: '/:cid/amdp/sandbox/unit-test/:id',
                name: 'Unit Test Execution',
                component: UnitTestExecution,
                alias: '/amdp/sandbox/unit-test/:id'
            },
            {
                path: '/:cid/amdp/sandboxes',
                name: 'Management',
                component: Management,
                alias: '/amdp/sandboxes'
            },
            {
                path: '/:cid/amdp/sandbox/users',
                name: 'Sandbox User Access',
                component: SandboxUserAccess,
            },
            {
                path: '/:cid/amdp/sandboxes/:id',
                name: 'SandboxDetail',
                component: SandboxDetail,
                alias: '/amdp/sandboxes/:id',
                meta: {
                    "cidChangeRedirectRoute": "Management"
                }
            }, {

                path: '/:cid/amdp/sandbox/:sandboxId/jobs',
                name: 'Sandbox Job List',
                component: SandboxJobList,
                alias: '/amdp/sandbox/:sandboxId/jobs',
                meta: {
                    "sandboxTypes": ["dev"]
                }
            },{
                path: '/:cid/amdp/sandbox/:sandboxId/promotions',
                name: 'Promotions',
                component: Promotions,
                meta: {
                    "sandboxTypes": ["prodV2"]
                }
            },{
                path:'/:cid/amdp/sandbox/:sandboxId/promotions/:promotionId',
                name: 'Promotion Detail',
                component: PromotionDetail,
                meta: {
                    "sandboxTypes": ["prodV2"],
                    "cidChangeRedirectRoute": "Promotions"
                },
                children: [
                    {
                        path:'/:cid/amdp/sandbox/:sandboxId/promotions/:promotionId/overview',
                        name: 'Promotion Overview',
                        component: PromotionOverview
                    },
                    {
                        path:'/:cid/amdp/sandbox/:sandboxId/promotions/:promotionId/run-history',
                        name: 'Promotion Run History',
                        component: PromotionRunHistory
                    },
                    {
                        path:'/:cid/amdp/sandbox/:sandboxId/promotions/:promotionId/configuration',
                        name: 'Promotion Configuration',
                        component: PromotionConfiguration
                    },
                    {
                        path:'/:cid/amdp/sandbox/:sandboxId/promotions/:promotionId/:jobId/executions/:executionId',
                        name: 'Promotion Execution Details',
                        component: ExecutionDetails
                    }
                ]
                
            },
            {
                path: '/:cid/amdp/sandbox/:sandboxId/jobs/:jobId',
                name: 'Sandbox Job Detail',
                component: SandboxJobDetail,
                meta: {
                    "sandboxTypes": ["dev"],
                    "cidChangeRedirectRoute": "Sandbox Job List"
                    
                },
                children: [
                    {
                        path: '/:cid/amdp/sandbox/:sandboxId/jobs/:jobId/configuration',
                        name: 'Sandbox Job Configuration',
                        component: Configuration,
                        alias: '/amdp/sandbox/:sandboxId/jobs/:jobId/configuration'
                    }, {
                        path: '/:cid/amdp/sandbox/:sandboxId/jobs/:jobId/run-history',
                        name: 'Sandbox Job Run History',
                        component: RunHistory,
                        alias: '/amdp/sandbox/:sandboxId/jobs/:jobId/run-history'
                    }, {
                        path: '/:cid/amdp/sandbox/:sandboxId/jobs/:jobId/execution-details/:executionId',
                        name: 'Sandbox Job Execution Details',
                        component: ExecutionDetails
                    }, {
                        path: '/:cid/amdp/sandbox/:sandboxId/jobs/:jobId/promotions',
                        name: 'Sandbox Job Promotions',
                        component: SandboxJobPromotions
                    },
                    {
                        path: '/:cid/amdp/sandbox/:sandboxId/jobs/:jobId/override',
                        name: 'Sandbox Job Override',
                        component: JobOverride
                    },
                    {
                        path: '/:cid/amdp/sandbox/:sandboxId/jobs/:jobId/meters',
                        name: 'Sandbox Job Meters',
                        component: JobMeters
                    }


                ]
            },
            {
                path: '/:cid/amdp/:sandboxId/credentials',
                name: 'Sandbox Credentials',
                component: SandboxCredentials,
                alias: '/amdp/:sandboxId/credentials',
                meta: {
                    "sandboxTypes": ["dev"]
                }
            },
            {
                path: '/:cid/amdp/:sandboxId/credentials/:id',
                name: 'Sandbox Credential',
                component: SandboxCredential,
                alias: '/amdp/:sandboxId/credentials/:id',
                meta: {
                    "sandboxTypes": ["dev"],
                    "cidChangeRedirectRoute": "Sandbox Credentials"
                }
            },
            {
                path: '/:cid/amdp/flow-set',
                name: 'Configurator (beta)',
                component: Configurator,
                alias: '/amdp/flow-set',
            },
            {
                path: '/:cid/amdp/flow-set/:id',
                name: 'Configurator Details',
                component: ConfiguratorDetails,
                alias: '/amdp/flow-set/:id',
            },
            {
                path: '/:cid/aio/usage',
                name: 'aIO Usage',
                component: aioUsage,
                alias: '/aio/usage',
            },
            {
                path: '/:cid/aio/dashboards',
                name: 'aIO Dashboards',
                component: aioDashboards,
                alias: '/aio/usage',
            },
            {
                path: '/:cid/aio/:sandboxId/handler-sandbox',
                name: 'aIO Handler Sandbox',
                component: aioHandlerSandbox,
                meta: {
                    "sandboxTypes": ["dev"]
                }                
            },
            {
                path: '/:cid/aio/:sandboxId/handler-sandbox/:jobId',
                name: 'aIO Handler Detail',
                component: HandlerDetails,
                meta: {
                    "sandboxTypes": ["dev"],
                    "cidChangeRedirectRoute": "aIO Handler Sandbox"
                    
                },
                children: [
                    {
                        path: '/:cid/aio/:sandboxId/handler-sandbox/:jobId/configuration',
                        name: 'aIO Handler Configuration',
                        component: HandlerConfiguration
                    },
                    {
                        path: '/:cid/aio/:sandboxId/handler-sandbox/:jobId/requests',
                        name: 'aIO Handler Requests',
                        component: HandlerRequests
                    }
                ]

            },
            {
                path: '/agm/dashboards',
                name: 'Graph Dashboards',
                component: agmDashboards,
            },
            {

                path: '/:cid/agm/sandbox/:sandboxId/jobs',
                name: 'IDR',
                component: IDRList,
                meta: {
                    "sandboxTypes": ["dev"]
                }
            },
            {
                path: '/:cid/agm/sandbox/:sandboxId/jobs/:jobId',
                name: 'IDR Job Detail',
                component: IDRJobDetail,
                meta: {
                    "sandboxTypes": ["dev"],
                    "cidChangeRedirectRoute": "IDRList"
                    
                },
                children: [
                    {
                        path: '/:cid/agm/sandbox/:sandboxId/jobs/:jobId/configuration',
                        name: 'IDR Job Configuration',
                        component: IDRConfiguration
                    },
                    
                ]

            },
            {
                path: '/:cid/agm/idr-visualizer',
                name: 'IDR Visualizer',
                component: IDRVisualizer
               

            },
            {
                path: 'apps/:appId',
                name: 'Client Apps',
                component: ClientApp,
                props: true
            }


        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    store.dispatch('setLoading', false)
    
    store.dispatch('dismissToast', 'route-render')

    const { $cookies } = router.app.config.globalProperties
    
     //Checking when the cache need to clear based on the module path
    if(from.path!='/' && to.path.split("/")[1] !=from.path.split("/")[1]){
        //Note: Function to clear datatable cache if other main menu items
        store.dispatch('setDatatableCache',[])
    }
    
   
    //for external forwards like helpjuice urls
    if (to.query.forwardTo) {
        store.dispatch('setForwardTo', to.query.forwardTo)
    }
    //for internal forwards like /aut/reports 
    if (to.query.path) {
        store.dispatch('setRedirectPath', to.query.path)
    }

    const proceedRoute = () => {
        if (store.state.forwardTo) {
            let goto = store.state.forwardTo
            store.dispatch('setForwardTo', null)
            window.location = goto
        } else if (store.state.redirectPath) {
            let goto = store.state.redirectPath;
            store.dispatch('setRedirectPath', null)
            console.log(goto);
            next(goto)
        } else {
            next()
        }
    }

    const checkCidAccess = () => {
        if(to.params.cid && !store.state.clients.some(client => client.cid === to.params.cid) ){
            store.dispatch('setRestrictedAccess', true)            
        } else {
            store.dispatch('setRestrictedAccess', false) 
        }
    }

    const setCurrentSandbox = () => {
        if(to.params.sandboxId) {
            store.dispatch('sandbox/setCurrentSandbox', to.params.sandboxId);
        }
    }

    //Authentication Logic
    if (to.name !== 'Login' && to.name !== 'Logout' && store.state.currentUser == null) {
        store.dispatch('setLoading', true)
        //check for incoming access Token through tkn parameter and redirect
        if (to.name == "Home" && to.query.tkn) {
            $cookies.set('access-token', to.query.tkn)
            next({ name: 'Home' })
        }
        //resolve incoming access token
        const accessToken = $cookies.get('access-token');
        if (accessToken) {
            store.dispatch('setAccessToken', accessToken)
        } else if (!store.state.accessToken && store.state.environment != 'test') {
            //redirect to login page
            store.dispatch('setRedirectPath', to.path)
            next({ name: 'Login' })
        }

        const authPromise = store.dispatch('whoAmICheck')
            .then((res) => {
                //From Who-ma-i getting Token expire time and creating new cookie variable
                $cookies.set('authExpireTime', res.data.token_expires_at, new Date(res.data.token_expires_at).toUTCString(), "/", null, null, 'Strict')
                $cookies.set('access-token', accessToken, new Date(res.data.token_expires_at).toUTCString(), "/", store.state.cookieDomain, null, 'Strict')
                $cookies.set('apiSpecUserRole', store.getters.userRoles.getUserRole([res.data.roles]), null, null, "aqfer.net", null, null)
            

                store.dispatch('handleUserData', res.data)
                /* 
                Logic for kicking out the user if not aqfer.com email
                $cookies.set('authExpireTime',res.data.token_expires_at,new Date(res.data.token_expires_at).toUTCString(),"/",null,null,'Strict')
                $cookies.set('access-token',accessToken,new Date(res.data.token_expires_at).toUTCString(),"/",store.state.cookieDomain,null,'Strict')
                
                const email=res.data.email
                const domain = email.substring(email.lastIndexOf("@") +1);
                if(domain == "aqfer.com"){
                    store.dispatch('handleUserData', res.data)
                }else{
                    alert("App is Beta version")
                    store.dispatch('logout')
                    next({ name: 'Login' })
                } */

                //load aUT c901 container tag once after login
                if (store.state.environment == 'prod' && window.location.hostname == 'app.aqfer.net') {
                    window.AqferData  = {
                        cdmn: store.state.tagUrl,
                        lm: 'e',
                        tt: 'tcs.dhj',
                        suu: 1,
                    };
                    !function (a) {
                        a.cls = 'portal';
                        a.aqet = 'othev';
                        a.aq_m = 1;
                        a.aqet_l1 = 'session';
                        a.dmn = 'app.aqfer.net';
                        a.pn = encodeURIComponent(to.path)
                        a.uu = store.state.currentUser.email
                    }(window.AqferData)
                    let p = window.AqferData;
                    let d = document;
                    let w = window;
                    let l = 'location';
                    var o, k = [], t = p.tt.slice(-1), x = { cdmn: 1, lm: 1, tt: 1 }, y = { cid: 1, cls: 1, dmn: 1, pubid: 1, evid: 1, aq_m: 1 };
                    p.dmn = (p.dmn || (w.top[l] === w[l] ? '' + w[l] : d.referrer).split('/')[2]).split(':')[0];
                    for (o in p) { if (y[o] || ((t != 'j' || p.aq_m) && !x[o])) { k.push(o + "=" + p[o]); } }
                    var s = d.createElement(t == 'f' ? 'img' : 'script'); s.id = s.title = 'pxscrpt';
                    s.async = s.defer = !0; s.src = '//' + p.cdmn + '/1/' + p.lm + '/' + p.tt + '?' + k.join("&");
                    d.body.appendChild(s);
                }
            })
            .catch((error) => {
                console.error(error)
                store.dispatch('setRedirectPath', to.path)
                next({ name: 'Login' })
            })
        //Call api only if access Token is present
        if (accessToken || store.state.environment == 'test') {
            const clientsPromise = axios
                .get(store.state.mgmtUrl + '/v1/staging/clients?detail=client', {
                    headers: {
                        'Authorization': `Bearer ${store.state.accessToken}`
                    }
                })
                .then((res) => {
                    store.dispatch('handleClientList', {clientsData:res.data.items, routeParams:to.params})
                    if (store.state.toastNotif == true) {
                        const options = {
                            message: "Successfully logged in as " + store.state.currentUser.email + ".",
                            type: "success",
                        }
                        store.dispatch('addToast', options)
                        store.state.toastNotif = false;
                    }
                }).catch((error) => {
                    console.error(error)
                })

            // helpjuice encrypted url
            // required iframe to unlock customer-assets in documentation
            const docsPromise = store.dispatch("getHelpjuiceURL").then((helpjuiceEncUrl)=>{
                if (helpjuiceEncUrl) {
                    fetch(helpjuiceEncUrl.url, {
                        mode: "no-cors",
                        credentials: 'include',
                    }).catch(error => {
                        console.error('Docs error', error);
                    });
                }
             });
            Promise.all([authPromise, clientsPromise, docsPromise]).then(() => {
                store.dispatch('setLoading', false);
                checkCidAccess(); 
                setCurrentSandbox();               
                proceedRoute()
            });

        }
    } else {
        checkCidAccess();
        setCurrentSandbox();
        next()
    }


})

router.afterEach((to, from, next) => {
    //Dynamic document title 
    let paramsStr = Object.entries(to.params).map(([k, v]) => `${k}: ${v}`).join(', ');
    document.title = (to.name)? `${to.name} -${paramsStr}- Aqfer Portal`: "Aqfer Portal";

    //aqfer c901 beacon tag implementation
    if (store.state.environment == 'prod' && window.location.hostname == 'app.aqfer.net') {
        let username = store.state.currentUser ? store.state.currentUser.email : "";
        let aqtUrl = "https://"+store.state.tagUrl+"/1/e/c.gif?aqet=pv&aq_m=1&dmn=app.aqfer.net&pn=" + encodeURIComponent(to.path)
        aqtUrl += "&uu=" + username
        // fetch(aqtUrl, {
        //     mode: "no-cors",
        //     credentials: 'include',
        // });
        let pixel = new Image();
        pixel.src = aqtUrl;
        pixel.style.display = "none";
        document.body.appendChild(pixel);
    }
})

export default router