<template>
	
	<table v-if="columns.length" class="table table-bordered table-sm collapsible-table" ref="dataTable">
		<thead>
			<tr>
				<th
					v-for="column in [...orderedColumns]"
					:key="column.data"
					:class="column.data == aggregateCol ? 'sum-col' : ''"
				>
					{{ column.title }}
				</th>
			</tr>
		</thead>
		<tbody>
			<template
				v-for="(group, groupIndex) in groupByData"
				:key="groupIndex"
			>
			
				<CollapsibleTR
					:subGroup="group.subGroups"
					:orderedColumns="orderedColumns"
					:groupBy="groupBy"
					:collapsedGroups="collapsedGroups"
					:sumByKey="sumByKey"
					:groupKey="group.key"
					:groupKeys="group.keys"
					:groupIndex="0"
					:rows="group.rows || []"
					:rowCountByKey="rowCountByKey"
					:unit = "units[group.key]"					
					@update:collapsedGroups="onUpdatCollpasedGroups"
				>
				</CollapsibleTR>
			</template>
		</tbody>
	</table>
    <div v-else id="error-div" class="m-5 p-5 text-center">
        <h5 >No data found!</h5>
    </div>
</template>

<script>
	import CollapsibleTR from "./CollapsibleTR";
	import csv from "../utils/tools/csv"
	export default {
		components: { CollapsibleTR },
		props: {
			data: {
				type: Array,
				required: true,
			},
			columns: {
				type: Array,
				required: true,
			},
			units: {
				type: Object,
				default: () => ({}),
			},
			groupBy: {
				type: Array,
				required: true,
			},
			aggregateCol: {
				type: String,
				required: true,
			},
            collapsibleCol: {
                type: String,
				required: true,
            },
			exportCurrentView: {
				type: Boolean,
				default: false
			},
			id: {
				type: String
			}
		},
		data() {
			return {
				collapsedGroups: {},
				sumByKey: {},
				rowCountByKey: {},
			};
		},
		watch: {
			exportCurrentView(oldValue, newValue) {
				this.exportToCsv();
			}

		},
		computed: {
			groupByData() {            
                const collapsibleColIndex = this.orderedColumns.findIndex(col => col.data == this.collapsibleCol);
                const groupByLevel = (data, groupByKeys, aggregateCol) => {
                    const groups = new Map();

                    for (const row of data) {
                        let currentGroup = groups;
                        let keys = [];

                        for (let i = 0; i < groupByKeys.length; i++) {
                            const groupKey = groupByKeys[i];
                            const key = row[groupKey];
                            keys.push(key);

                            let group = currentGroup.get(key);

                            if (!group) {
                                group = {
                                    key,
                                    keys: [...keys],
                                    subGroups: new Map(),
                                    sum: 0,
                                    rowLength: 0,
                                };
                                currentGroup.set(key, group);
                            }

                            if (i === groupByKeys.length - 1) {
                                const value = row[aggregateCol];
                                const filterCondition = groupByKeys.every(
                                    (filterKey, index) => {
                                        const rowValue = row[filterKey];
                                        const keyValue = group.keys[index];
                                        return rowValue === keyValue;
                                    }
                                );

                                if (filterCondition) {
                                    group.rows = group.rows || [];
                                    group.rows.push(row);
                                    group.sum += value;
                                    group.rowLength += 1;
                                }
                                this.sumByKey[group.keys.join(",")] = group.sum;
                                this.rowCountByKey[group.keys.join(",")] = group.rowLength;
                            } else {
                                currentGroup = group.subGroups;
                            }

							
                            if(collapsibleColIndex !== -1 && group.keys.length == collapsibleColIndex+1) {
                                this.collapsedGroups[group.keys.join(",")] = true;
                            }
                        }
                    }

                    const calculateParentSums = (group) => {
                        if (group.subGroups.size > 0) {
                            for (const subGroup of group.subGroups.values()) {
                                calculateParentSums(subGroup);
                                group.sum += subGroup.sum;
                                group.rowLength += subGroup.rowLength;
                            }
                            this.sumByKey[group.keys.join(",")] = group.sum;
                            this.rowCountByKey[group.keys.join(",")] = group.rowLength;
                        }
                    };

                    for (const group of groups.values()) {
                        calculateParentSums(group);
                    }

            return [...groups.values()];
                };

                const result = groupByLevel(this.data, this.groupBy, this.aggregateCol);
                return result;
            },
			orderedColumns() {
				const orderedColumns = [...this.columns];
				const groupByColumns = this.groupBy.map((key) =>
					this.columns.find((col) => col.data === key)
				);
				const otherColumns = orderedColumns.filter(
					(col) => !groupByColumns.includes(col)
				);
				return [...groupByColumns, ...otherColumns];
			}			
		},
		methods: {			
			onUpdatCollpasedGroups(groupKey) {
				this.collapsedGroups[groupKey] =
					!this.collapsedGroups[groupKey];
			},
			exportToCsv() {
				const table = this.$refs.dataTable;
				const csvContent = this.tableToCSV(table);
				const title = `${this.id}.csv`;

				const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
				const link = document.createElement('a');
				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob);
					link.setAttribute('href', url);
					link.setAttribute('download', title);
					link.style.visibility = 'hidden';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			},
			tableToCSV(table) {
				const rows = Array.from(table.rows);
				const headerRow = rows[0];
				const dataRows = rows.slice(1);

				// Get visible header cells and their indices
				const visibleHeaders = Array.from(headerRow.cells)
					.map((cell, index) => ({ cell, index }))
					.filter(({ cell }) => this.isCellVisible(cell));

				// Create CSV content
				const csvRows = [
					visibleHeaders.map(({ cell }) => this.formatCellContent(cell.textContent.trim())).join(','),
					...dataRows
					.filter(row => this.isRowVisible(row))
					.map(row => {
						return visibleHeaders.map(({ index }) => {
							let cell = row.cells[index];
							if(index == visibleHeaders.length-1 && !this.isCellVisible(cell)){
								cell = row.cells[index+1];
							}
							return this.isCellVisible(cell) ?this.formatCellContent(cell.textContent.trim()) : '';
						}).join(',');
					})
					.filter(row => row.length > 0 && !(/^,*$/.test(row)))
				];

				return csvRows.join('\n');
			},
			isRowVisible(row) {
				return row.offsetParent !== null && getComputedStyle(row).display !== 'none';
			},
			isCellVisible(cell) {
				return cell && cell.offsetParent !== null && getComputedStyle(cell).display !== 'none';
			},
			formatCellContent(content) {
				const escaped = content.replace(/"/g, '""');
				return `"${escaped}"`;
			},
		},
	};
</script>

<style>	
.collapsible-table > :not(caption) > * > *:not(th) {
    padding: 0.5rem 0.5rem;
    font-weight: bold;
}
.collapsible-table > * > th {
   font-size: 0.9rem;
   font-weight: bold;
}
.sum-col {
    text-align: end;
}
</style>
