<template>
    <basic-header
        pretitle="aqfer Marketing Data Platform"
        title="Flow Sets"
        buttonText="Create Flow Set"
        @btnClick="showModal({ component: 'createFlowSet' ,data:{screenlarge: false}})"
    />

    <div class="container-fluid">
        <div class="card">
            <datatable
                tableId="configurationsTable"
                :columns="columns"
                uniqueField = "id"
                :data="$store.state.amdp.flowSet"
                @deleteRows="showDeleteFlowSets"
                :deletable="true"
                :compact="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'flow-set/', name: 'Configurator Details', identifier:'id'}"
            />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapMutations } from "vuex";

export default {
    name: "Configurator",
    components: { BasicHeader, Datatable },
    data() {
        return {
            columns: [
                { data: "id" },
                { data: "name" },
            ],
        };
    },
    beforeMount() {
        this.$store.dispatch("amdp/fetchFlowSets");
        this.$store.dispatch("admin/fetchJobTemplates");        
    },
    methods: {
        showDeleteFlowSets(rows) {
            this.showModal({ component: "deleteFlowSets", data: rows });           
        },
        ...mapActions({
            showModal: "showModal",
        }),
    },
};
</script>

<style scoped>
</style>