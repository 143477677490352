<template>
	<div class="container-fluid">
		<form
			class="tab-content py-6"
			id="wizardSteps"
			@submit.prevent="onCreateFlowSet()"
		>
			<!-- Data Source Step -->
			<div
				class="tab-pane fade show active"
				id="wizardStepOne"
				role="tabpanel"
				aria-labelledby="wizardTabOne"
			>
				<!-- Header -->
				<div class="row justify-content-center">
					<div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
						<!-- Pretitle -->
						<h6 class="mb-4 text-uppercase text-muted">
							Step 1 of {{ totalSteps }}
						</h6>
						<!-- Title -->
						<h1 class="mb-3">
							<!-- Let’s start with template creation. -->
							Begin by adding a data source
						</h1>
						<!-- Subtitle -->
						<p class="mb-5 text-muted">Description</p>
					</div>
				</div>
				<!-- / .row -->
				<div class="row">
					<div class="nav d-grid gap-2 col-6 mx-auto">
						<a
							type="button"
							class="btn btn-primary btn-lg"
							data-toggle="wizard"
							href="#wizardStepTwo"
							>Browse Files</a
						>
					</div>
				</div>
				<div class="row">
					<div class="nav d-grid gap-2 col-6 mx-auto text-center">
						<h3 class="mt-5">Commercial Integrations</h3>
						<Tiles
							:tiles="commercialIntegrationsTile"
							@onSelectTile="onSelectCommercialIntegration"
							:selectedTile="configuration.commercialIntegration"
						></Tiles>
					</div>
				</div>

				<!-- Divider -->
				<hr class="my-5" />

				<!-- Footer -->
				<div class="nav row align-items-center">
					<div class="col-auto">
						<!-- Button -->
						<button
							class="btn btn-lg btn-white"
							type="reset"
							data-bs-dismiss="modal"
						>
							Cancel
						</button>
					</div>
					<div class="col text-center">
						<!-- Step -->
						<h6 class="text-uppercase text-muted mb-0">
							Step 1 of {{ totalSteps }}
						</h6>
					</div>
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-primary"
							v-show="configuration.commercialIntegration"
							data-toggle="wizard"
							href="#wizardStepThree-one"
							role="button"
							>Continue</a
						>
					</div>
				</div>
			</div>

			<!-- File Browser Step -->
			<div
				class="tab-pane fade"
				id="wizardStepTwo"
				role="tabpanel"
				aria-labelledby="wizardTabTwo"
			>
				<!-- Header -->
				<div class="row justify-content-center">
					<div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
						<!-- Pretitle -->
						<h6 class="mb-4 text-uppercase text-muted">
							Step 2 of {{ totalSteps }}
						</h6>
						<!-- Title -->
						<h1 class="mb-3">Select the s3 path</h1>
						<!-- Subtitle -->
						<p class="mb-5 text-muted">description</p>
					</div>
				</div>
				<!-- / .row -->
				<div class="row">
					<div class="col">
						<FileBrowser @onFileSelect="fileSelected"></FileBrowser>
					</div>
				</div>

				<!-- Divider -->
				<hr class="my-5" />

				<!-- Footer -->
				<div class="nav row align-items-center">
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-white"
							data-toggle="wizard"
							href="#wizardStepOne"
							>Back</a
						>
					</div>
					<div class="col text-center">
						<!-- Step -->
						<h6 class="text-uppercase text-muted mb-0">
							Step 2 of {{ totalSteps }}
						</h6>
					</div>
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-primary"
							data-toggle="wizard"
							href="#wizardStepThree"
							role="button"
						>
							<span>Continue</span>
						</a>
					</div>
				</div>
			</div>

			<!-- Data Set -->
			<div
				class="tab-pane fade"
				id="wizardStepThree"
				role="tabpanel"
				aria-labelledby="wizardTabThree"
			>
				<!-- Header -->
				<div class="row justify-content-center">
					<div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
						<!-- Pretitle -->
						<h6 class="mb-4 text-uppercase text-muted">
							Step 3 of {{ totalSteps }}
						</h6>
						<!-- Title -->
						<h1 class="mb-3">What is captured in this Data Set?</h1>
						<!-- Subtitle -->
						<p class="mb-5 text-muted"></p>
					</div>
				</div>
				<!-- / .row -->
				<div class="row">
					<div class="nav d-grid gap-2 col-6 mx-auto">
						<Tiles
							:tiles="DataSetTiles"
							@onSelectTile="onSelectDataSet"
							:selectedTile="configuration.dataSet"
						></Tiles>
					</div>
				</div>

				<!-- Divider -->
				<hr class="my-5" />

				<!-- Footer -->
				<div class="nav row align-items-center">
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-white"
							data-toggle="wizard"
							href="#wizardStepTwo"
							>Back</a
						>
					</div>
					<div class="col text-center">
						<!-- Step -->
						<h6 class="text-uppercase text-muted mb-0">
							Step 3 of {{ totalSteps }}
						</h6>
					</div>
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-primary"
							data-toggle="wizard"
							:href="
								configuration.dataSet == 'attribute'
									? '#wizardStepFour'
									: '#wizardStepThree-one'
							"
							>Continue</a
						>
					</div>
				</div>
			</div>
			<!-- event type -->
			<div
				class="tab-pane fade"
				id="wizardStepThree-one"
				role="tabpanel"
				aria-labelledby="wizardTabThree-one"
			>
				<!-- Header -->
				<div class="row justify-content-center">
					<div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
						<!-- Pretitle -->
						<h6 class="mb-4 text-uppercase text-muted">
							Step
							{{
								configuration.commercialIntegration ? "2" : "4"
							}}
							of
							{{ totalSteps }}
						</h6>
						<!-- Title -->
						<h1 class="mb-3">Select event types</h1>
						<!-- Subtitle -->
						<p class="mb-5 text-muted"></p>
					</div>
				</div>
				<!-- / .row -->
				<div class="row">
					<div class="nav d-grid gap-2 col-6 mx-auto">
						<Tiles
							:tiles="eventTypeTiles"
							@onSelectTile="onSelectEventType"
							:selectedTile="configuration.eventType"
						></Tiles>
					</div>
				</div>

				<!-- Divider -->
				<hr class="my-5" />

				<!-- Footer -->
				<div class="nav row align-items-center">
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-white"
							data-toggle="wizard"
							:href="
								configuration.commercialIntegration
									? '#wizardStepOne'
									: '#wizardStepTwo'
							"
							>Back</a
						>
					</div>
					<div class="col text-center">
						<!-- Step -->
						<h6 class="text-uppercase text-muted mb-0">
							Step
							{{
								configuration.commercialIntegration ? "2" : "5"
							}}
							of
							{{ totalSteps }}
						</h6>
					</div>
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-primary"
							data-toggle="wizard"
							href="#wizardStepFour"
							>Continue</a
						>
					</div>
				</div>
			</div>
			<!-- Input Entity Domain -->
			<div
				class="tab-pane fade"
				id="wizardStepFour"
				role="tabpanel"
				aria-labelledby="wizardTabFour"
			>
				<!-- Header -->
				<div class="row justify-content-center">
					<div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
						<!-- Pretitle -->
						<h6 class="mb-4 text-uppercase text-muted">
							Step
							{{
								configuration.commercialIntegration ? "3" : "5"
							}}
							of
							{{ totalSteps }}
						</h6>
						<!-- Title -->
						<h1 class="mb-3">Input Entity Domain:</h1>
						<!-- Subtitle -->
						<p class="mb-5 text-muted"></p>
					</div>
				</div>
				<!-- / .row -->
				<div class="row">
					<div class="nav d-grid gap-2 col-6 mx-auto">
						<input
							type="text"
							id="domain"
							class="form-control mx-sm-3"
							v-model="configuration.entityDomain"
						/>
					</div>
				</div>

				<!-- Divider -->
				<hr class="my-5" />

				<!-- Footer -->
				<div class="nav row align-items-center">
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-white"
							data-toggle="wizard"
							:href="
								configuration.commercialIntegration
									? '#wizardStepThree-one'
									: '#wizardStepThree'
							"
							>Back</a
						>
					</div>
					<div class="col text-center">
						<!-- Step -->
						<h6 class="text-uppercase text-muted mb-0">
							Step
							{{
								configuration.commercialIntegration ? "3" : "4"
							}}
							of
							{{ totalSteps }}
						</h6>
					</div>
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-primary"
							data-toggle="wizard"
							href="#wizardStepFive"
							>Continue</a
						>
					</div>
				</div>
			</div>
			<!-- Select Job template group -->
			<div
				class="tab-pane fade"
				id="wizardStepFive"
				role="tabpanel"
				aria-labelledby="wizardStepFive"
			>
				<!-- Header -->
				<div class="row justify-content-center">
					<div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
						<!-- Pretitle -->
						<h6 class="mb-4 text-uppercase text-muted">
							Step
							{{
								configuration.commercialIntegration ? "4" : "5"
							}}
							of
							{{ totalSteps }}
						</h6>
						<!-- Title -->
						<h1 class="mb-3">Job Template Group:</h1>
						<!-- Subtitle -->
						<p class="mb-5 text-muted"></p>
					</div>
				</div>
				<!-- / .row -->
				<div class="row">
					<div class="nav d-grid gap-2 col-6 mx-auto">
						<select
							class="form-select mx-sm-3"
							v-model.number="jobTemplateGroupId">
							<option value="" disabled selected>Select job template group</option>
							<option
								v-for="option in jobTemplateGroupList"
								:key="option.id"
								:value="option.id">
								{{ option.name }}
							</option>
						</select>
					</div>
				</div>

				<!-- Divider -->
				<hr class="my-5" />

				<!-- Footer -->
				<div class="nav row align-items-center">
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-white"
							data-toggle="wizard"
							href="#wizardStepFour"
							>Back</a
						>
					</div>
					<div class="col text-center">
						<!-- Step -->
						<h6 class="text-uppercase text-muted mb-0">
							Step
							{{
								configuration.commercialIntegration ? "4" : "5"
							}}
							of
							{{ totalSteps }}
						</h6>
					</div>
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-primary"
							data-toggle="wizard"
							href="#wizardStepSix"
							>Continue</a
						>
					</div>
				</div>
			</div>
			<!-- configuration Result -->
			<div
				class="tab-pane fade"
				id="wizardStepSix"
				role="tabpanel"
				aria-labelledby="wizardTabSix"
			>
				<!-- Header -->
				<div class="row justify-content-center">
					<div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
						<!-- Pretitle -->
						<h6 class="mb-4 text-uppercase text-muted">
							Step
							{{
								configuration.commercialIntegration ? "5" : "6"
							}}
							of
							{{ totalSteps }}
						</h6>
						<!-- Title -->
						<h1 class="mb-3">Flow Set</h1>
						<!-- Subtitle -->
						<p class="mb-5 text-muted"></p>
					</div>
				</div>
				<!-- / .row -->
				<div class="row">
					<div class="nav d-grid gap-2 col-12 mx-3">
						<div class="row">
							<div class="col-md-6">
								<div class="mb-3">
									<h3
										for="exampleFormControlInput1"
										class="form-label"
									>
										Name
									</h3>
									<input
										class="form-control"
										id="exampleFormControlInput1"
										v-model="name"
										placeholder="Enter name for the flow set"
									/>
								</div>
							</div>
						</div>

						<div v-for="(value, key) in configuration" :key="key">
							<div class="row" v-if="value">
								<div class="col-3">
									<!-- Title -->
									<small>{{ key }}</small>
								</div>
								<div class="col-9 px-3">
									<strong>{{ value }}</strong>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Divider -->
				<hr class="my-5" />

				<!-- Footer -->
				<div class="nav row align-items-center">
					<div class="col-auto">
						<!-- Button -->
						<a
							class="btn btn-lg btn-white"
							data-toggle="wizard"
							href="#wizardStepFive"
							>Back</a
						>
					</div>
					<div class="col text-center">
						<!-- Step -->
						<h6 class="text-uppercase text-muted mb-0">
							Step
							{{
								configuration.commercialIntegration ? "5" : "6"
							}}
							of
							{{ totalSteps }}
						</h6>
					</div>
					<div class="col-auto">
						<div class="col-auto">
							<!-- Button -->
							<button
								class="btn btn-lg btn-primary"
								type="submit"
							>
								<div
									v-if="loading"
									class="spinner-border spinner-border-sm"
									role="status"
								>
									<span class="visually-hidden"
										>Loading...</span
									>
								</div>
								<div v-else>Create</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { Tab } from "bootstrap";
	import FileBrowser from "./../sandbox/FileBrowser";
	import Tiles from "../Tiles.vue";
	import { mapActions } from "vuex";
	export default {
		components: { FileBrowser, Tiles },
		data() {
			return {
				configuration: {
					dataSet: null,
					entityDomain: null,
					eventType: null,
					commercialIntegration: null,
				},
                cid: this.$route.params.cid,
				flowSet: null,
				job: {},
				totalSteps: 6,
				name: null,
				DataSetTiles: [
					{ title: "Attribute", value: "attribute" },
					{ title: "Event", value: "event" },
				],
				eventTypeTiles: [
					{
						title: "Impression",
						subTitle:
							"An impression (also known as a view-through) is when a user sees an advertisement.",
						value: "imp",
					},
					{
						title: "Click",
						subTitle:
							"To register that a click has occured by the user.",
						value: "clk",
					},
					{
						title: "Engagement",
						subTitle:
							"When the user engages with particular content.",
						value: "eng",
					},
					{
						title: "Page View",
						subTitle:
							"A single instance of a user visiting a particular page on a website",
						value: "pv",
					},
				],
				commercialIntegrationsTile: [
					{ title: "aUT", value: "aUT" },
					{ title: "TTD", value: "TTD" },
					{ title: "GCM", value: "GCM" },
				],
				groupTemplates: [],
				loading: false,
				jobTemplateGroupList:[],
				jobTemplateGroupId:"",				
			};
		},
		mounted() {
			this.getJobTemplateGroupList();
			const toggles = document.querySelectorAll('[data-toggle="wizard"]');
			toggles.forEach((toggle) => {
				toggle.addEventListener("click", function (e) {
					e.preventDefault();
					// Toggle new tab
					const tab = new Tab(toggle);
					tab.show();
					// Remove active state
					toggle.classList.remove("active");
				});
			});
		},

		methods: {
			...mapActions({
				createFlowSet: "amdp/createFlowSet",
				getJobTemplateGroups: "amdp/getJobTemplateGroups",
			}),
			async onCreateFlowSet() {
				this.loading = true;
				let toastoptions;
				let response;
				try {
					response = await this.createFlowSet({
						configuration: JSON.stringify(this.configuration),
						name: this.name,
						status: "INITIATED",
						type:'AMDP',
						job_template_group_id: this.jobTemplateGroupId
					});
					this.flowSet = response.data;
					toastoptions = {
						message: "Flow set created successfully.",
						type: "success",
					};
				} catch (error) {
					if (error.response.data.error) 
						this.error = error.response.data.error
					else 
						this.error = error.message

						toastoptions = {
						message: this.error ||
							"Something went wrong when creating flow set. Please contact support.",
						type: "error",
					};
					this.loading = false;
					this.$store.dispatch("addToast", toastoptions);
					return;
				}
				this.$store.dispatch("hideModal");
				this.$router.push(
					`/${this.cid}/amdp/flow-set/${this.flowSet.id}`
				);
				this.$store.dispatch("addToast", toastoptions);
			},
			async getJobTemplateGroupList() {
				let toastoptions;
				let response;
				try {
					response = await this.getJobTemplateGroups();
					this.jobTemplateGroupList = response.data;
				} catch (error) {
					toastoptions = {
						message:
							"Something went wrong while fetching job template group. Please contact support.",
						type: "error",
					};
					this.$store.dispatch("addToast", toastoptions);
					return;
				}
			},
			fileSelected(filePath) {
				this.configuration.commercialIntegration = null;
				this.configuration.s3FilePath = filePath;
			},
			onSelectDataSet(val) {
				this.configuration.dataSet = val;
			},
			onSelectEventType(val) {
				this.configuration.eventType = val;
			},
			onSelectCommercialIntegration(val) {
				this.configuration.commercialIntegration = val;
				this.totalSteps = 5;
			},
		}        
	};
</script>

<style></style>
