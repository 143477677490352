<template>
  <DetailHeader pretitle="aqfer Marketing Data Platform" :editable="false" secondaryTitle="Flow Set" 
  :title="flowSet.name"
  @closeClick="$router.push(`/${cid}/amdp/flow-set`) "/>
  <div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card"  v-if="flowSet.configuration">
                <div class="card-header">
                    <h5 class="card-header-title">DETAILS</h5>
                </div>
                <div class="card-body pt-0">
                    <div class="list-group list-group-flush">
                                <div v-for="(item, index) in listItems" :key="index" class="list-group-item">
                                    <div class="row">
                                        <div class="col">
                                            <!-- Title -->
                                            <small>{{ item.title }}</small>
                                        </div>
                                        <div class="col-auto">
                                            <small v-if="item.title=='Status'" class="text-primary" >
                                            <strong>{{ flowSet[item.data] }}</strong>                                            </small>
                                           
                                            <small v-else-if="item.data == 'created_at'" class="text-muted" >
                                            <strong>{{  DateTimeFormat(flowSet[item.data] ) }}</strong><span class="badge badge-secondary">{{ convertToRelativeTime(flowSet[item.data]) }}</span>
                                            </small>
                                            <small v-else-if="item.data == 'updated_at'" class="text-muted" >
                                            <strong>{{  DateTimeFormat(flowSet[item.data] ) }}</strong><span class="badge badge-secondary">{{ convertToRelativeTime(flowSet[item.data]) }}</span>
                                            </small>                          
                                            <small v-else class="text-muted" >
                                            <strong>{{ flowSet[item.data] }}</strong> 
                                            </small>
                                        </div>
                                    </div> <!-- / .row -->
                                </div>                            
                            </div>

                </div>
            </div>
        </div>  
        <div class="col" >
            <div class="card" role="" v-if="flowSet?.configuration" >
                    <div class="card-header p-4 ">                  
                        <h5 class="card-header-title">
                            JOBS CREATED
                        </h5>
                    </div>
                    <div class="card-body">
                        <div v-for="(job, index) in jobNames" :key="index" class="list-group-item">
                           <button  class="btn btn-link  text-decoration-underline link-primary" @click="redirectToSanboxJob(job)"><strong>{{job}}</strong></button>
                        </div>
                    </div>  
                </div>
            <div class="card"  v-if="flowSet?.configuration">
                <div class="card-header">
                  <h5  class="card-header-title">CONFIG </h5>
                </div>
                <div class="card-body pt-0">
                    <div class="list-group list-group-flush">
                        <div v-for="(value, key, index) in config" :key="index" class="list-group-item">
                            <div class="row" v-if="key!='jobNames'">
                                <div class="col">
                                    <!-- Title -->
                                    <small>{{ key }}</small>
                                </div>
                                <div class="col-auto">
                                    <small >
                                    <strong>{{ value }}</strong>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div> 
         
    </div>
  </div>  
</template>

<script>

import { mapActions } from "vuex";
import DetailHeader from "../../components/DetailHeader.vue";
import dayjs from 'dayjs';
import { DateTimeFormat,relativeTime } from '../../utils/commonFunction';
export default {
    name: "ConfiguratorDetails",
    components: { DetailHeader },
    data() {
        return {           
            id: this.$route.params.id,
            cid:  this.$route.params.cid,
            flowSet: {},
            listItems: [
                { data: "status", title: "Status"  },
                { data: "created_by" , title: "Created By"},
                { data: "updated_by" , title: "Updated By"},
                { data: "created_at" , title: "Created At"},
                { data: "updated_at" , title: "Updated At"},

            ],
        }
    },
    beforeMount() {
        this.fetchFlowSet();
    },
    computed:{
        jobNames(){
            if(this.flowSet && this.flowSet.configuration){
                try {
                    let jobNames =JSON.parse(this.flowSet.configuration).jobNames
                    jobNames = jobNames.split(",")
                    return jobNames
                } catch (e) {
                    return [];
                }    
            }
            return []
        },
        config(){
            if(this.flowSet && this.flowSet.configuration){
                try {
                    let config =JSON.parse(this.flowSet.configuration)
                    return config
                } catch (e) {
                    return {};
                }    
            }
            return {}
        }
    },
    methods: {
        ...mapActions({
            readFlowSet: "amdp/readFlowSet",
            readOverrideFile: "sandbox/readOverrideFile",
        }),
        DateTimeFormat,
        async fetchFlowSet() {
            let response;
            let self = this;
            try {
                response = await this.readFlowSet(this.id);
                this.flowSet = response.data;     
            } catch (error) {
                if (error.response.data.error) this.error = error.response.data.error;
                else this.error = error.message;
            } 
            
        },
        convertToRelativeTime(timestamp){
            return relativeTime(Date.now(), dayjs(timestamp));
        }, 
        redirectToSanboxJob(jobName) {
            this.$router.push({
                    name: "Sandbox Job Configuration",
                    params: {
                        sandboxId: `${this.$store.state.sandbox.currentSandbox}`,
                        jobId: `${jobName}`
                    },
                }); 
        },
    }
}
</script>

<style>

</style>