<template>
    <div class="modal-card card">
        <form @submit.prevent="onFormSubmit()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Delete Job Template Groups</h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <p>Are you sure you want to delete the following job template groups?</p>
                <ul class="list-group list-group-sm">
                    <li v-for="item in modalData" :key="item.index" class="list-group-item">
                        {{ item.name }}
                    </li>
                </ul>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">
                    <div v-if="deleteInProgress" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else>
                        <slot></slot>Delete
                    </div>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            error: "",
            deleteInProgress: false
        };
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "DeleteJobTemplateGroup",
    methods: {
        ...mapActions("admin", {
            deleteJobTemplateGroup: "deleteJobTemplateGroup",
        }),
        async onFormSubmit() {
            this.deleteInProgress = true;
            try {
                for (const item of this.modalData) {
                    await this.deleteJobTemplateGroup(item.id)
                }
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            } finally {
                this.deleteInProgress = false;
            }
            this.$store.dispatch("hideModal");
            this.$store.dispatch("admin/fetchJobTemplateGroups");
        },
    },
};
</script>

<style></style>