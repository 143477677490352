<template>
    <basic-header pretitle="aqfer IO" title="aIO Usage" :compact="true" />
    <div class="container-fluid">
        <div class="row g-3 align-items-center mt-3">
            <div class="col-auto">
                <InputField colField="mb-0" :editable="true" label="Month" :options="monthOptions" type="select"
                    :modelValue="usageMonth" @change="usageMonthChange($event)" />
            </div>
            <div class="col-auto">
                <InputField label="Start Date" colField="mb-0" :modelValue="startDate" type="calendar"
                    :input-config="dateConfig" @update:modelValue="startDateChange($event)" />
            </div>
            <div class="col-auto">
                <InputField label="End Date" colField="mb-0" :modelValue="endDate" type="calendar"
                    :input-config="dateConfig" @update:modelValue="endDateChange($event)" />
            </div>
            <div class="col-auto">
                <div class="form-group">
                    <label class="form-label">
                        Show all
                    </label>
                    <div class="custom-control form-switch">
                        <input type="checkbox" :class="['form-check-input', { 'bg-secondary': !showAll }]"
                            id="customSwitch1" v-model="showAll">
                    </div>
                </div>
            </div>
        </div>
        <Tabs :tabs="tabItems">
            <template v-slot:daily>
                <div class="card">
                    <div class="card-body chart-view">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <Line v-else :data="dailyChartData" :options="chartOptions" />
                    </div>
                </div>
            </template>
            <template v-slot:aggregate>
                <div class="card">
                    <div class="card-body">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-6" v-if="aggregateData">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Key</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in aggregateTableData" :key="item.index">
                                            <td>{{ item.label }}</td>
                                            <td>
                                                {{ formatNumber(item.value) }}{{ aggregateUnits[item.label] ? `
                                                (${aggregateUnits[item.label]})` : '' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6 chart-view">
                                <Bar :data="aggregateChartData" :options="chartOptions" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:statusCode>
                <div class="card">
                    <div class="card-body">
                        <div class="col-auto mb-4">
                            <InputField colField="mb-0 w-25" :editable="true" label="Status Code"
                                :options="statusCodeOptions" type="select" :modelValue="statusCode"
                                @change="statusCodeChange($event)" />
                        </div>
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-6" v-if="statusCodeUsage">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Key</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in aggregateStatusCodeTableData" :key="item.index">
                                            <td>{{ item.label }}</td>
                                            <td>
                                                {{ formatNumber(item.value) }}{{ statusCodeUnits[item.label] ? `
                                                (${statusCodeUnits[item.label]})` : '' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6 chart-view">
                                <Bar :data="aggregateStatusCodeChartData" :options="chartOptions" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:infraUsage>
                <div class="card">
                    <div class="card-body">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div v-else>
                            <CollapsibleTable id="infra-usage-records" :data="infraUsageData"
                                :columns="infraUsageColumns" :units="usageInfraDailyUnits"
                                :collapsibleCol="infraCollapsibleCol" :groupBy="groupByInfraLabel" aggregateCol="value"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </Tabs>
    </div>
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
import InputField from "../../components/InputField.vue";
import CollapsibleTable from "../../components/CollapsibleTable.vue";
import Tabs from "../../components/Tabs.vue";
import dayjs from "dayjs";
import { mapActions, mapState } from "vuex";
import { generateLastSixMonthOptions, getFirstDayOfMonth, getLastDayOfMonth, formatNumber } from '../../utils/commonFunction';
import { Bar, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors)

const DEFAULT_METRICS = ["aIOGet", "aIOPut", "aIODetailLogging"]

export default {
    components: { Bar, Line, BasicHeader, InputField, Tabs, CollapsibleTable },

    data() {
        return {
            loading: true,
            showAll: false,
            statusCode: "2",
            statusCodeOptions: [
                { value: '2', label: '2xx (Success)' },
                { value: '4', label: '4xx (Client Error)' },
                { value: '5', label: '5xx (Server Error)' },
            ],
            tabItems: [
                {
                    name: "Daily",
                    id: "id1",
                    slot: "daily"
                },
                {
                    name: "Aggregate",
                    id: "id2",
                    slot: "aggregate"
                },
                {
                    name: "Status Code",
                    id: "id3",
                    slot: "statusCode"
                },
                {
                    name: "Infra Usage",
                    id: "id3",
                    slot: "infraUsage"
                },
            ],
            dateConfig: {
                wrap: true,
                dateFormat: 'Y-m-d'
            },
            chartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    colors: {
                        forceOverride: true
                    }
                }
            },
            monthOptions: generateLastSixMonthOptions(),
            infraUsageData:[],
            infraUsageColumns: [
                { data: "meter_label", title: "meter_label" },
                { data: "date", title: "date" },
                { data: "value", title: "value" }
            ],
            groupByInfraLabel: ["meter_label", "date"],
            infraCollapsibleCol: "meter_label",
            infraMeterLabels: ["DynamoDBStorage"],
            statusCodeUsage: [],
        }
    },

    methods: {
        ...mapActions({
            fetchUsageData: "aio/fetchUsageData",
            fetchInfraUsageData: "admin/fetchInfraUsageData",
            fetchUsageDataByStatusCode: "aio/fetchUsageDataByStatusCode",
        }),
        formatNumber,
        async usageMonthChange(month) {
            this.$store.dispatch('aio/setUsageMonth', month);
            const startDate = getFirstDayOfMonth(month);
            const endDate = getLastDayOfMonth(month);
            this.$store.dispatch('aio/setUsageDataStartDate', startDate);
            this.$store.dispatch('aio/setUsageDataEndDate', endDate);
            this.$store.dispatch('admin/setInfraUsageDataStartDate', startDate);
            this.$store.dispatch('admin/setInfraUsageDataEndDate', endDate);
            await this.getUsageData();

        },

        async startDateChange(newValue) {
            if (newValue !== this.$store.state.aio.usageDataStartDate) {
                this.$store.dispatch('aio/setUsageMonth', 'custom');
                this.$store.dispatch('aio/setUsageDataStartDate', newValue);
                this.$store.dispatch('admin/setInfraUsageDataStartDate', newValue);
                await this.getUsageData();
            }

        },
        async endDateChange(newValue) {
            if (newValue !== this.$store.state.aio.usageDataEndDate) {
                this.$store.dispatch('aio/setUsageMonth', 'custom');
                this.$store.dispatch('aio/setUsageDataEndDate', newValue)
                this.$store.dispatch('admin/setInfraUsageDataEndDate', newValue);
                await this.getUsageData();
            }

        },
        async statusCodeChange(newValue) {
            if (newValue !== this.statusCode) {
                this.statusCode = newValue
                await this.getStatusCodeUsageData();
            }

        },
        async getStatusCodeUsageData() {
            await this.fetchUsageDataByStatusCode({
                usageType: "aggregate",
                filterByStatusCode: true
            });
        },

        async getUsageData() {
            this.loading = true
            await this.fetchUsageData("daily");
            await this.fetchUsageData("aggregate");
            await this.fetchUsageDataByStatusCode({
                usageType: "aggregate",
                filterByStatusCode: true
            });
            await this.fetchInfraUsageData("daily");
            this.loading = false
        }

    },
    watch: {
        usageInfraDailyData: {
            handler(newValue) {
                if (newValue) {
                    this.infraUsageData = []
                    for (const dataPoint of newValue) {
                        const date = dataPoint[this.usageInfraDailyHeaders.indexOf('date')]
                        const dataLabel = dataPoint[this.usageInfraDailyHeaders.indexOf('key')]
                        const value = dataPoint[this.usageInfraDailyHeaders.indexOf('value')]

                        if (dataLabel && (this.infraMeterLabels.some(substring => dataLabel.includes(substring)))) {
                            const obj = { "date": date, "meter_label": dataLabel, "value": value };
                            this.infraUsageData.push(obj)
                        }
                    }
                }
            },
            deep: true
        },
        statusCodeData: {
            handler(newValue) {
                this.statusCodeUsage = null
                if (newValue) {
                    let aggregateData = new Map();
                    for (const dataPoint of newValue) {
                        const sc = dataPoint[this.statusCodeHeaders.indexOf('sub_label')]
                        const key = dataPoint[this.statusCodeHeaders.indexOf('key')]
                        const value = parseInt(dataPoint[this.statusCodeHeaders.indexOf('value')])
                        if (Math.floor(parseInt(sc) / 100) === parseInt(this.statusCode)) {
                            const currentValue = aggregateData.get(key) || 0
                            aggregateData.set(key, currentValue + value)
                        }
                    }
                    this.statusCodeUsage = Array.from(aggregateData)
                }
            },
            deep: true
        }
    },

    computed: {
        ...mapState({
            startDate: state => state.aio.usageDataStartDate,
            endDate: state => state.aio.usageDataEndDate,
            usageDailyData: state => state.aio.usageData.daily ? state.aio.usageData.daily.data : [],
            usageDailyHeaders: state => state.aio.usageData.daily ? state.aio.usageData.daily.headers : [],
            usageDailyUnits: state => state.aio.usageData.daily ? state.aio.usageData.daily.units : {},
            aggregateData: state => state.aio.usageData.aggregate ? state.aio.usageData.aggregate.data : [],
            aggregateHeaders: state => state.aio.usageData.aggregate ? state.aio.usageData.aggregate.headers : [],
            aggregateUnits: state => state.aio.usageData.aggregate ? state.aio.usageData.aggregate.units : {},
            usageMonth: state => state.aio.usageMonth,

            statusCodeData: state => state.aio.usageDataStatusCode.aggregate ? state.aio.usageDataStatusCode.aggregate.data : {},
            statusCodeHeaders: state => state.aio.usageDataStatusCode.aggregate ? state.aio.usageDataStatusCode.aggregate.headers : {},
            statusCodeUnits: state => state.aio.usageDataStatusCode.aggregate ? state.aio.usageDataStatusCode.aggregate.units : {},

            usageInfraDailyData: state => state.admin.infraUsageData.daily ? state.admin.infraUsageData.daily.data : [],
            usageInfraDailyHeaders: state => state.admin.infraUsageData.daily ? state.admin.infraUsageData.daily.headers : [],
            usageInfraDailyUnits: state => state.admin.infraUsageData.daily ? state.admin.infraUsageData.daily.units : {},
        }),

        dailyChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }

            if (this.usageDailyData) {
                let labels = []
                let dataLabelsObj = {}
                let datasetsObj = {}

                // First pass: collect all unique dates and keys
                for (const dataPoint of this.usageDailyData) {
                    const date = dataPoint[this.usageDailyHeaders.indexOf('date')]
                    const dataLabel = dataPoint[this.usageDailyHeaders.indexOf('key')]
                    const value = dataPoint[this.usageDailyHeaders.indexOf('value')]

                    if (dataLabel && (this.showAll || DEFAULT_METRICS.some(substring => dataLabel.includes(substring)))) {
                        // Add date to labels if not already present
                        if (!labels.includes(date)) {
                            labels.push(date)
                        }

                        // Mark this dataLabel as existing
                        dataLabelsObj[dataLabel] = true

                        // Initialize dataset object if it doesn't exist
                        if (!datasetsObj[dataLabel]) {
                            datasetsObj[dataLabel] = {}
                        }

                        // Store the value indexed by date
                        datasetsObj[dataLabel][date] = value
                    }
                }

                // Sort the labels (dates)
                labels.sort()

                // Convert the datasets object to the required array format
                let datasets = Object.keys(dataLabelsObj).map(dataLabel => {
                    // Create an array of values matching the labels array
                    const data = labels.map(date => {
                        return datasetsObj[dataLabel][date] || 0
                    })

                    return {
                        label: this.usageDailyUnits[dataLabel] ? `${dataLabel} (${this.usageDailyUnits[dataLabel]})` : dataLabel,
                        data: data
                    }
                })

                chartData = {
                    labels: labels,
                    datasets: datasets
                }
            }

            return chartData;
        },

        aggregateTableData() {
            var tableData = []
            for (const dataPoint of this.aggregateData) {
                var dataLabel = dataPoint[this.aggregateHeaders.indexOf('key')]
                var value = dataPoint[this.aggregateHeaders.indexOf('value')]
                if (dataLabel && (this.showAll || DEFAULT_METRICS.some(substring => dataLabel.includes(substring)))) {
                    tableData.push({
                        label: dataLabel,
                        value: value
                    })
                }
            }
            return tableData
        },

        aggregateChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            if (this.aggregateData) {

                let labels = []
                let dataset = []
                for (const dataPoint of this.aggregateData) {
                    var dataLabel = dataPoint[this.aggregateHeaders.indexOf('key')]
                    var value = dataPoint[this.aggregateHeaders.indexOf('value')]
                    if (dataLabel && (this.showAll || DEFAULT_METRICS.some(substring => dataLabel.includes(substring)))) {
                        const unit = this.aggregateUnits[dataLabel];
                        labels.push(unit ? `${dataLabel} (${unit})` : dataLabel);
                        dataset.push(value)
                    }
                }

                chartData = {
                    labels: labels,
                    datasets: [{ label: "Count", data: dataset }]
                }
            }

            return chartData
        },

        aggregateStatusCodeTableData() {
            var tableData = []
            for (const dataPoint of this.statusCodeUsage) {
                var dataLabel = dataPoint[this.statusCodeHeaders.indexOf('key')]
                var value = dataPoint[this.statusCodeHeaders.indexOf('value')]
                if (dataLabel && (this.showAll || DEFAULT_METRICS.some(substring => dataLabel.includes(substring)))) {
                    tableData.push({
                        label: dataLabel,
                        value: value
                    })
                }
            }
            return tableData
        },

        aggregateStatusCodeChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            if (this.statusCodeUsage) {

                let labels = []
                let dataset = []
                for (const dataPoint of this.statusCodeUsage) {
                    var dataLabel = dataPoint[this.statusCodeHeaders.indexOf('key')]
                    var value = dataPoint[this.statusCodeHeaders.indexOf('value')]
                    if (dataLabel && (this.showAll || DEFAULT_METRICS.some(substring => dataLabel.includes(substring)))) {
                        const unit = this.statusCodeUnits[dataLabel];
                        labels.push(unit ? `${dataLabel} (${unit})` : dataLabel);
                        dataset.push(value)
                    }
                }

                chartData = {
                    labels: labels,
                    datasets: [{ label: "Count", data: dataset }]
                }
            }

            return chartData
        },

    },

    async beforeMount() {
        await this.getUsageData();
    },


}
</script>

<style scoped>
.chart-view {
    height: 50vh !important;
}
</style>