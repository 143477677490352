import axios from 'axios';
const store = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getProductUsage({ rootState, state, commit }, payload) {
            // Build query parameters dynamically
            const queryParams = new URLSearchParams({
                start_date: payload.startDate,
                end_date: payload.endDate
            });

            // Add aggregate parameter if usageType is aggregate
            if (payload.usageType === 'aggregate') {
                queryParams.append('aggregate', 'true');
            }
            // Add status_code parameter if needs to be filtered
            if (payload.subLabel) {
                queryParams.append('filter_sub_label', payload.subLabel);
            }

            const baseUrl = `${rootState.countsUrl}/v2/cids/${rootState.currentClient.cid}/products/${payload.product}/usage`;

            const handlerUrl = `${baseUrl}?${queryParams.toString()}`;

            return axios.get(handlerUrl, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                if (response.data) {
                    return response.data
                }
                return response;
            }).catch((e) => {
                return e;
            })
        }

    }
}
export default store;