<template>
    <div v-if="error" class="text-danger mb-2">
                    {{ error }}
    </div>
    <div v-if="message" class="container-fluid" >
        <div class="alert alert-secondary" role="alert">
            {{ message }}
        </div>        
    </div>
    <div class="row mx-3" v-if="selectedSandbox || selectedBucket || selectedCredential" :class="dropDownDisable?'dropDownMenu':''">
        <div v-if="sandboxOptions.length>0 || dropDownLoading=='sandbox'" class="col-md-6 col-lg-3 mb-2">
            <label class="form-label">Choose a Sandbox <span class="text-danger">&nbsp;*</span></label>
            
            <select  v-if="selectedSandbox" class="form-select form-control"  v-model="selectedSandbox">
                    <option  v-for="option in sandboxOptions" :key="option.index" :value="option.value">
                        {{ option.label }}
                    </option>
            </select>
            <div v-else class="spinner-border spinner-border-sm loader">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div  v-if="credentialOptions.length>0 || dropDownLoading=='credential'" class="col-md-6 col-lg-3 mb-2">
            <label class="form-label">Choose a Credential <span class="text-danger">&nbsp;*</span></label>
            <select v-if="selectedCredential" class="form-select form-control" @change="selectedCredential=$event.target.value" v-model="selectedCredential">
                    <option v-for="option in credentialOptions" :key="option.index" :value="option.value">
                        {{ option.label }}
                    </option>
            </select>
            <div v-else 
                class="spinner-border spinner-border-sm loader">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div  v-if="bucketOptions.length>0 || dropDownLoading=='bucket'" class="col-md-6 col-lg-3 mb-2">
            <label class="form-label">Choose a bucket <span class="text-danger">&nbsp;*</span></label>
            <select v-if ="selectedBucket" class="form-select form-control" @change="onSelectBucket($event.target.value)" v-model="selectedBucket">
                    <option v-for="option in bucketOptions" :key="option.index" :value="option.value">
                        {{ option.label }}
                    </option>
            </select>
            <div  v-else class="spinner-border spinner-border-sm loader ">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>       
    </div>
    <div v-if="selectedBucket" class="mx-4 my-3">
        <Breadcrumbs :breadcrumbItems="this.$store.state.breadcrumbs" :key="this.$store.state.breadcrumbs.length" />
        <Folder 
            :tableId="selectedBucket"
            :data="filesList" baseUrl='' :loading="loading" name='File Browser Folder' identifier='folderName' icon='folder'  @fetchSubFolder = "fetchSubFolder" @selectedFiles="selectedFiles"/> 

    </div>
    
  
</template>

<script>
import { mapActions } from 'vuex';
import Folder from "../../components/FolderView.vue";
import Breadcrumbs from "../../components/Breadcrumbs.vue";

export default {
    components: { Folder ,Breadcrumbs},
    data() {
        return {
            bucketList:[],
            filesList: [],
            credentialList:[],
            loading: true,
            dropDownLoading: '',
            selectedSandbox : null,
            selectedBucket: null,
            selectedCredential:null,
            error :null,
            unmountRoute: null,
            message: null,
            subFolderId: null
        };
    },
    computed: {
        sandboxOptions(){
            return this.$store.state.sandbox.devSandboxes? this.$store.state.sandbox.devSandboxes.map((sandbox) => {
                return {"label": sandbox.name, "value": sandbox.name };                
            }) : [];
            
        },
        credentialOptions(){
            if(this.credentialList && this.credentialList.length >0){
                return this.credentialList.map((credential)=>{
                    return {label: credential.client_credential_name, value: credential.client_credential_name}
                })
            }
            return []
        },
        bucketOptions(){
            if(this.bucketList && this.bucketList.length >0){
                return this.bucketList.map((bucket)=>{
                    return {label: bucket.name, value: bucket.name}
                })
            }
            return []
        },
        folderPath(){
           return this.$store.state.amdp.folderPath;
        },
        dropDownDisable(){
            return this.dropDownLoading !=''
        }
       
        
    },
    watch: {       
        async selectedSandbox(newSandboxName){
            this.dropDownLoading="credential"
            this.message=null;
            this.error= null;
            this.selectedCredential='';
            this.selectedBucket=''
            this.$store.dispatch('setBreadcrumbs',[])  
            this.bucketList =[]
            if(newSandboxName && newSandboxName != ""){
                this.$store.dispatch("sandbox/setCurrentSandbox", newSandboxName);
                let response = await this.getSandboxCredentials(newSandboxName);
                if(!response.data){
                    this.message=`There is no credential for sandbox ${this.selectedSandbox}`
                    this.dropDownLoading=''
                    this.credentialList=[]
                }
                if(response.data && response.data.length>0){ 
                    this.credentialList= response.data.filter((cred)=>cred.type=="aws")
                }
            }else{
                this.message ="No Sandboxes available!"
            }
            this.loading = false
        },
        credentialList(newValue){
            let credential;
            if(newValue && newValue.length>0){
                this.selectedCredential=newValue[0].client_credential_name
                credential=this.selectedCredential
            }else{
                credential=null  
            }
            this.$store.dispatch('amdp/setSelectedCredential',credential)
        },
        async selectedCredential(newValue){
            this.dropDownLoading="bucket";
            if(newValue && newValue != ""){
                this.message=null;
                this.error= null;
                this.selectedBucket='';
                this.$store.dispatch('setBreadcrumbs',[])  
                this.bucketList =[]
                this.$store.dispatch('amdp/setSelectedCredential',newValue)
                await this.fetchBucketList() 
            }
        },
        selectedBucket(to, from){
            if ((( !from && !to ) ||(from !==to))) {  
                this.setFolderPathAndFetchFielList();                
            }
        },
        filesList() {
            this.filesList.map((file) => {
                file['folderName'] = file.name.replace("/", '')
            });
        } 
    },
    methods: {
        ...mapActions({
            getBucketList: "amdp/getBucketList",
            getSandboxCredentials: "amdp/getSandboxCredentials",  
            getFileBrowserList: "amdp/getFileBrowserList",
        }),
        setInitialSelectSandbox() {
            const defaultSandboxRegex = /(c\d\d\d-dev)$/g
                if(!this.sandboxOptions.length) {
                    this.selectedSandbox = null;
                } else {
                    const filteredSandboxList = this.sandboxOptions.filter((e) => {
                        return e.value.match(defaultSandboxRegex);                    
                    })
                    this.selectedSandbox =  (filteredSandboxList.length)?filteredSandboxList[0].value:this.sandboxOptions[0].value;
                    this.$store.dispatch("sandbox/setCurrentSandbox", this.selectedSandbox);
                } 
        },
        fetchSubFolder(subFolderId) {
            this.setFolderPathAndFetchFielList(subFolderId);
        },
        setFolderPathAndFetchFielList(subFolderId) {
            let path =this.folderPath
            let refresh =false;
            if (path == "" || path.endsWith("@")){
                let credential=this.$store.state.amdp.selectedCredential
                //setting credential in path
                path ="s3://"+(credential?this.$store.state.currentClient.cid+'.'+credential+'@':'')+this.selectedBucket+"/"+("")
                refresh= true
            }
            else if(subFolderId){
                path =path+subFolderId +"/"
                refresh= true
            }
            if(refresh){
                this.$store.dispatch('amdp/setFolderPath',path.replace("slash/","/").replace("///","//")); 
                this.filesList=[]
                this.fetchFileBrowserList(this.folderPath)
            }
            
        },
        selectedFiles(selectedFile) {
            if(selectedFile) {
                let path =  this.folderPath;
                let selectedS3Files = path+selectedFile.name;
                this.$emit('onFileSelect', selectedS3Files);
            }                   
        },
        async fetchBucketList(refresh=false){
            let response;
            this.loading=true;
            this.error=null;
            if(refresh){
               this.dropDownLoading= 'bucket'
            }
            try {
                let param ={
                    credential:this.$route.params.cid+"."+this.selectedCredential,
                    type:"s3",
                    sandboxName:this.selectedSandbox
                }
                response = await this.getBucketList(param);
            } catch (error) {
                if (error.response.data.error) this.error = error.response.data.error;
                else this.error = error.message;
                this.dropDownLoading=''
                this.$store.dispatch('setBreadcrumbs',[]) 
                this.loading = false;
                return;
            }
            this.bucketList = await response.data? response.data :[];
            if(this.bucketList && this.bucketList.length >0){
                //On reload based on path selecting the bucket
                this.selectedBucket = this.$route.params.bucket?this.$route.params.bucket:this.bucketList[0].name;
                this.onSelectBucket(this.selectedBucket)
            }else{
                this.selectedBucket =""
                this.$store.dispatch('setBreadcrumbs',[])  
                this.message=`There is no bucket for sandbox ${this.selectedSandbox} and credential ${this.selectedCredential}`
            }
            this.dropDownLoading=''
            this.loading = false
        },
        onSelectBucket(bucket){
            let breadcrumbPath=[{
                name:bucket,
                route:{
                    name: "File Browser List",
                    params: {
                                bucket: `${bucket}`,
                                cid:this.$store.state.currentClient.cid,
                                sandboxId:this.selectedSandbox
                            }
                    }
            }]
            //Showing the bucket folders
            this.$store.dispatch('setBreadcrumbs',breadcrumbPath)
            //whenever bucket changes reset folder path
            this.$store.dispatch('amdp/setFolderPath',"");
            //to avoid unmount reloading
            if(this.unmountRoute)
                return
            // this.$router.push({
            //     name:'File Browser List',
            //     params: {
            //          bucket: `${bucket}`,
            //          cid:this.$store.state.currentClient.cid,
            //          sandboxId:this.selectedSandbox
            //         } 
            // })
        },
        async fetchFileBrowserList(folderPath) {
            this.error =null
            this.filesList=[]
            this.loading = true 
           
            if ((folderPath && folderPath!="s3://")) {
                let response;
                try {
                    response = await this.getFileBrowserList({folderName: folderPath, sandboxName:this.selectedSandbox});
                } catch (error) {
                    this.filesList=[]
                    this.loading = false
                    if (error.response.data.error) this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
                this.filesList = await response.data? response.data :[];
                this.loading=false
            } else if(folderPath && folderPath!="s3://"){
                // this.$router.push({ 
                //     name: 'File Browser View',
                //     path: this.$route.params.id,
                //     params: { 
                //         id: this.$route.params.id,
                //         data: this.$route.params.data
                //     } 
                // });

            }

        }
    },
    mounted() {
        this.setInitialSelectSandbox();
    },
    emits: ['onFileSelect']

}
</script>

<style>

</style>