<template>
    <div class="row job-config">
        <div id="col-3">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-header-title">System Files</h5>
                    <button type="button" class="btn  btn-sm"></button>
                </div>
                <div>
                    <div class="card-body py-3">
                        <p v-if="!inputVal.length">No configuration files found.</p>
                        <list-group :editMode="editMode" :title="'File List'" :list="inputVal"
                            :activeListItem="activeTab" listItemKey="relative_path" @deleteBtnClick="onFileDelete"
                            @selectBtnclick="showFile"></list-group>
                        <div v-if="editMode" class="mb-4">
                            <button v-if="!addingFile" @click="showAddFile" class="btn btn-sm btn-primary float-end"
                                type="button">
                                Add File
                            </button>
                            <div v-else>
                                <form @submit.prevent="addFile">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="example.yaml"
                                            v-model="newFileName" autofocus>
                                        <button @click="addFile" class="btn btn-outline-secondary"
                                            type="submit">OK</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col pipeline-config" id="main-col">
            <job-editor v-on:tabChange="showTab" v-on:onLintFailure="onLintFailure" :editMode="editMode"
                v-model="yamlContent" :activeTab="activeTab"></job-editor>
        </div>
    </div>
</template>

<script>
import JobEditor from '../../components/sandbox/JobEditor.vue';
import ListGroup from '../../components/ListGroup.vue';
import { mapActions } from "vuex";
export default {
    name: "JobConfig",
    components: { JobEditor, ListGroup },
    data() {
        return {
            activeTab: "",
            yamlContent: {},
            addingFile: false,
            newFileName: '',
            inputVal:this.modelValue ? this.modelValue:[]
        };
    },
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        editMode: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        'yamlContent': {
            handler: function (newVal) {
                for (const i of this.inputVal) {
                    i["content"] = this.yamlContent[i.relative_path]
                }
            },
            deep: true
        },
        'inputVal': {
            handler: function (newVal) {
                this.inpuVal = newVal
            },
            deep: true
        },
    },
    methods: {
        ...mapActions({
            showModal: "showModal"
        }),
        showFile(file) {
            this.activeTab = file.relative_path
        },
        showTab(tab) {
            this.activeTab = tab;
        },
        showAddFile() {
            this.newFileName = '';
            this.addingFile = true;
        },
        addFile() {
            this.addingFile = false;

            if (!this.inputVal)
                this.inputVal = [];

            this.inputVal.push({
                relative_path: this.newFileName
            })
            this.yamlContent[this.newFileName] = "";
            this.activeTab = this.newFileName;
        },
        onLintFailure(isError) {
            this.$emit("onLintFailure", isError);
        },
        onFileDelete(file) {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete job config file",
                    message: `Are you sure you want to delete file ${file.relative_path}?`,
                    async confirm() {
                        self.inputVal = self.inputVal.filter(obj => obj.relative_path != file.relative_path);
                        delete self.yamlContent[file.relative_path];
                        if (self.activeTab === file.relative_path)
                            self.activeTab = Object.keys(self.yamlContent)[0];
                        self.$emit("update:modelValue", self.inputVal);
                        return
                    },
                },
            });
        },
        initateYamlContent() {
            let yamlContent = {};

            if (this.inputVal.length) {
                for (const i of this.inputVal) {
                    yamlContent[i.relative_path] = i.content
                }
            }
            //Initially activeTab value is empty or undefined otherwise no need to reset to first value
            if (!this.activeTab || this.activeTab == "")
                this.activeTab = Object.keys(yamlContent)[0];
            this.yamlContent = JSON.parse(JSON.stringify(yamlContent));
        },
    },
    mounted() {
        if (!this.inputVal.length) {
            this.yamlContent["config.yaml"] = "";
            this.showTab("config.yaml")
        } else this.initateYamlContent()
    },
    emits: ["update:modelValue", "change"]
}
</script>

<style scoped>
.job-config .card-header {
    background-color: #F9FBFD;
    text-transform: uppercase;
    color: #95AAC9;
    height: 40px;
}
.job-config .pipeline-config{
    max-height: 300px;
    overflow-y: auto
}
</style>