<template>
    <basic-header pretitle="aqfer Marketing Data Platform" title="aMDP Usage" :compact="true" />
    <div class="container-fluid">
               
        <div class="row g-3 align-items-center mt-3">
            <div class="col-auto">
                <InputField colField="mb-0" :editable="true" label="Month" :options="monthOptions"
                    type="select" :modelValue="usageMonth" @change="usageMonthChange($event)" />
            </div>
            <div class="col-auto">
                <InputField label="Start Date"  colField="mb-0" :modelValue="startDate" type="calendar"
                    :input-config="dateConfig"  @update:modelValue="startDateChange($event)"/>
            </div>
            <div class="col-auto">
                <InputField label="End Date"  colField="mb-0" :modelValue="endDate" type="calendar"
                    :input-config="dateConfig"  @update:modelValue="endDateChange($event)"/>
            </div>            
            <div class="col-auto min-col" v-if="activeTab=='Job Metered Records' && meterLabelOptions.length">
                <input-field colField="mb-0" :editable="true" label="Meter Label" :options="meterLabelOptions"
                    type="multiselect" v-model="currentMeterLabel" />
            </div>
            <div class="col-auto  min-col" v-if="activeTab=='Job Metered Records' && meterTypeOptions.length">
                <input-field colField="mb-0" :editable="true" label="Metric Type" :options="meterTypeOptions"
                    type="multiselect" v-model="currentMetricType" />
                    
            </div>
            <div class="col-auto min-col" v-if="activeTab=='Job Metered Records' && jobOptions.length">
                <input-field colField="mb-0" :editable="true" label="Job" :options="jobOptions"
                    type="multiselect" v-model="currentJob" />
            </div>
            <div class="col-auto min-col" v-if="activeTab=='Records By Job' && recordJobOptions.length">
               <input-field colField="mb-0" :editable="true" label="Job Name" :options="recordJobOptions"
                                        type="multiselect" v-model="currentRecordsJob" />
            </div>
            <div class="col-auto min-col" v-if="activeTab=='Records By Job' && dataChannelOptions.length">
                <input-field colField="mb-0" :editable="true" label="Data Channel" :options="dataChannelOptions"
                                        type="multiselect" v-model="currentDataChannel" />
            </div>
            <div class="col-auto min-col" v-if="activeTab=='Records By Job' && stageOptions.length">
                <input-field colField="mb-0" :editable="true" label="Stage" :options="stageOptions"
                                        type="multiselect" v-model="currentStage" />
            </div>
        </div>
       
        <div class="row justify-content-end" v-if="activeTab=='Records By Job' || activeTab=='Job Metered Records' || activeTab=='Infra Metered Records'">                                    
            <div  class="col-sm-3 d-flex position-absolute mt-3 me-3 justify-content-end" >
                <div class="dropdown">
                    <button class="btn btn-primary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Export
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#" @click="exportViewCSV()">Current View (CSV)</a></li>
                        <li><a class="dropdown-item" href="#" @click="exportCSV()">All Data (CSV)</a></li>
                    </ul>
                </div>
                
            </div>           
        </div>  
        <Tabs :tabs="tabItems">            
            <template v-slot:charts>
                <div class="card">
                    <div class="card-body">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-7 mb-5 chart-view">
                                <h4 class="text-center" v-if="usageDailyData?.length">Daily</h4>
                                <Line :data="dailyChartData" :options="chartOptions"/>
                            </div>
                            <div class="col-5 mb-5 chart-view" v-if="aggregateData?.length">
                                <h4 class="text-center" >Aggregate</h4>
                                <Bar :data="aggregateChartData" :options="chartOptions" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:metered>
                <div class="card">
                    <div class="card-body" > 
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>  
                        <div v-else> 
                            <CollapsibleTable
                                id="metered-records"
                                :data="filteredData"
                                :columns="meteredColumns"
                                :groupBy="groupByMeter"
                                :collapsibleCol="meterCollapsibleCol"
                                aggregateCol="value"
                                :exportCurrentView="exportMRCurrentView"
                                />
                        </div>   

                    </div>
                </div>
            </template>
            <template v-slot:recordsByJob>
                <div class="card" >
                    <div>   
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div> 
                        <div v-else>
                            <div class="card">
                                <div class="card-body">
                                    <CollapsibleTable
                                    id="records-by-job"
                                    :data="filteredJobsData"
                                    :columns="recordsByJobDataColumns"
                                    :groupBy="groupByJobs"
                                    :collapsibleCol="jobRecordsCollapsibleCol"
                                    aggregateCol="record_count"
                                    :exportCurrentView="exportJRCurrentView"
                                    /> 

                                </div> 
                            </div>
                            <div class="card"> 
                                <div class="card-body">
                                    <div class="row mb-2">
                                    
                                    <div class="col float-start align-middle"><h4>Records By Job Daily Chart</h4> </div> 
                                    <div class="col-4 float-end" v-if="activeTab=='Records By Job' && recordJobOptions.length">
                                        <div class="input-group">
                                            <span class="input-group-text"><span class="me-2"> <i class="fa fa-filter"></i></span>Job</span>
                                            <div class="flex-grow-1">
                                                <input-field class="w-80" colField="mb-0" :editable="true" :showLabel="false" :options="recordJobOptions"
                                                                type="multiselect" v-model="currentRecordsJobForChart" />  
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                <div class="row">
                                    <div class="col-12  chart-view">                        
                                        <Line  :data="dailyJobChartData" :options="chartOptions"/>
                                    </div>
                                </div>
                                </div>                                
                            </div>
                        </div> 
                    </div>
                </div>
            </template>
            <template v-slot:infraMetered>
                <div class="card">
                    <div class="card-body" > 
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>  
                        <div v-else> 
                            <CollapsibleTable
                                id="infra-metered-records"
                                :data="infraMeteredData"
                                :columns="infraMeteredColumns"
                                :units="usageInfraDailyUnits"
                                :groupBy="groupByInfraMeter"
                                :collapsibleCol="meterCollapsibleCol"
                                aggregateCol="value"
                                :exportCurrentView="exportIMRCurrentView"
                                />
                        </div>   

                    </div>
                </div>
            </template>
        </Tabs>
    </div>
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
import InputField from "../../components/InputField.vue";
import Tabs from "../../components/Tabs.vue";
import CollapsibleTable from "../../components/CollapsibleTable.vue";
import axios from 'axios';
import { generateLastSixMonthOptions , getFirstDayOfMonth, getLastDayOfMonth} from '../../utils/commonFunction';
import { mapActions, mapState } from "vuex";
import csv from "../../utils/tools/csv"
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors } from 'chart.js'
import { Bar, Line } from 'vue-chartjs'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors)

export default {
    components: { Bar, Line, BasicHeader, InputField, Tabs , CollapsibleTable},

    data() {
        return {
            loading: true,
            tabItems: [                
                {                
                    name: "Charts",
                    id: "id1",
                    slot: "charts"
                },
                {                
                    name: "Job Metered Records",
                    id: "id3",
                    slot: "metered"
                },
                {                
                    name: "Records By Job",
                    id: "id4",
                    slot: "recordsByJob"
                },
                {
                    name: "Infra Metered Records",
                    id: "id4",
                    slot: "infraMetered"
                }
            ],
            dateConfig: {
                wrap: true,
                dateFormat: 'Y-m-d'
            },
            filteredData:[],
            meteredData:[],
            meteredColumns:[],
            recordsByJobData:[],
            filteredJobsData:[],
            filteredJobsChartData:[],
            recordsByJobDataColumns:[],
            meterLabelOptions:[],
            meterTypeOptions:[],
            jobOptions:[],
            currentMeterLabel:["All"],
            currentMetricType:["All"],
            currentJob: ["All"],            
            currentRecordsJob:["All"],
            currentRecordsJobForChart:["All"],
            currentDataChannel:["All"],
            currentStage:["All"],
            dataChannelOptions:[],
            stageOptions:[],
            recordJobOptions:[],
            groupByMeter:["meter_label","sandbox_name","job_name","metric_type"] ,
            groupByJobs:["sandbox_name","job_name","stage"] ,  
            groupByInfraMeter:["meter_label","date"] ,
            meterCollapsibleCol: "meter_label",
            jobRecordsCollapsibleCol:"stage",
            chartOptions:{
                maintainAspectRatio: false,
                responsive: true,   
                plugins: {
                    colors: {
                        forceOverride: true
                    }
                }              
            },
            exportMRCurrentView:  false,
            exportJRCurrentView:  false,
            exportIMRCurrentView:  false,
            monthOptions:generateLastSixMonthOptions(),
            infraMeteredData:[],
            infraMeteredColumns:[
                {data:"meter_label",title:"meter_label"},
                {data:"date",title:"date"},
                {data:"value",title:"value"}
            ]
        }
    },

    methods: {
        ...mapActions({
            fetchUsageData: "amdp/fetchUsageData",
            fetchInfraUsageData: "admin/fetchInfraUsageData",
        }),
        getMeteredRecords() {
            this.meteredData =[];
            this.meteredColumns=[];
            const postArguments = {
                cid: `${this.cid}`,
                start_datetime: `${this.startDate}`,
                end_datetime: `${this.endDate}`,
            };
            // Revisit code when api rewuest inlcude filters
            // if (this.currentMeterLabel !== 'All') {
            //     postArguments.meter_label = this.currentMeterLabel;
            // }
            // if (this.currentMetricType !== 'All') {
            //     postArguments.meter_type = this.currentMetricType;
            // }
            // if ( this.currentJob !== 'All') {
            //     postArguments.job_name = this.currentJob;
            // }
            return axios.post(`${this.$store.state.countsUrl}/v2/query/amdp-metered-records-v2/exec`,
            {
                "arguments": postArguments
            },
            {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response)=>{
                if(response.data) {
                    const { data, headers } = response.data.data;
                    
                    this.meteredData = data.map(row => {
                        const obj = {};
                        headers.forEach((header, index) => {
                            if (header !== 'cid') {
                                obj[header] = row[index];
                            }
                        });
                        return obj;
                    });
                    this.filteredMeteredData();
                    
                    headers.forEach( headerKey=> {
                        if (headerKey !== 'cid')
                            this.meteredColumns.push({data:headerKey,title:headerKey});
                    });
                    this.meterLabelOptions = this.createOptionsByColName(data, headers,"meter_label")
                    this.meterTypeOptions = this.createOptionsByColName(data, headers,"metric_type")
                    this.jobOptions = this.createOptionsByColName(data, headers,"job_name")
                   
                   
                }                    
                
            }).catch((error) => {
                if(error.response && error.response.status == 403) {
                    this.tabItems = this.tabItems.filter(item => item.slot !== 'metered');
                }
               throw error;
            })
        },
        createOptionsByColName(data, headers,colName) {
            let options = [];
            const columnIndex = headers.indexOf(colName);

            if (columnIndex !== -1) {
            options = data
                .map(row => row[columnIndex])
                .filter((value, index, self) => self.indexOf(value) === index)
                .map(label => ({ label, value: label }))
            } 

            options.unshift({ label: 'All', value: 'All' });
            return options;
        },
        getRecordsByJob() {
            this.recordsByJobDataColumns=[];
            this.recordsByJobData=[];
            return axios.post(`${this.$store.state.countsUrl}/v2/query/records-by-job/exec`,
            {
                "arguments": {"cid": `${this.cid}`, "start_datetime": `${this.startDate}`, "end_datetime": `${this.endDate}`}
            },
            {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response) => {
                if(response.data) {
                    const { data, headers } = response.data.data;
                    this.recordsByJobData = data.map(row => {
                        const obj = {};
                        headers.forEach((header, index) => {
                            if (header !== 'cid') {
                                obj[header] = row[index];
                            }
                            
                        });
                        return obj;
                    });
                    this.filteredRecordsByJob();
                    this.filteredChartRecordsByJob();
                    
                    headers.forEach( headerKey=> {
                        if (headerKey !== 'cid')
                            this.recordsByJobDataColumns.push({data:headerKey,title:headerKey});
                    });

                    this.recordJobOptions = this.createOptionsByColName(data, headers,"job_name")
                    this.dataChannelOptions = this.createOptionsByColName(data, headers,"data_channel")
                    this.stageOptions = this.createOptionsByColName(data, headers,"stage")

                }    
            }).catch((error) => {
                if(error.response && error.response.status == 403) {
                    this.tabItems = this.tabItems.filter(item => item.slot !== 'recordsByJob');
                }
               throw error;
            })

        },

        filteredMeteredData() {
            this.filteredData = this.meteredData.filter(item => {
                const meterLabelMatch = this.currentMeterLabel === null || this.currentMeterLabel.length === 0 || this.currentMeterLabel.includes('All') || this.currentMeterLabel.includes(item.meter_label);
                const metricTypeMatch = this.currentMetricType === null || this.currentMetricType.length === 0 || this.currentMetricType.includes('All') || this.currentMetricType.includes(item.metric_type);
                const jobNameMatch = this.currentJob === null || this.currentJob.length === '0' || this.currentJob.includes('All') || this.currentJob.includes(item.job_name);

                return meterLabelMatch && metricTypeMatch && jobNameMatch;
            });
        },

        filteredRecordsByJob() {
            this.filteredJobsData = this.recordsByJobData.filter(item => {
                const dataChennelMatch = this.currentDataChannel === null || this.currentDataChannel.length === 0 || this.currentDataChannel.includes('All') || this.currentDataChannel.includes(item.data_channel);
                const stageMatch = this.currentStage === null || this.currentStage.length === 0 ||this.currentStage.includes('All') || this.currentStage.includes( item.stage );
                const jobNameMatch = this.currentRecordsJob === null || this.currentRecordsJob.length === 0 || this.currentRecordsJob.includes('All') || this.currentRecordsJob.includes(item.job_name);

                return dataChennelMatch && stageMatch && jobNameMatch;
            });
        },
        filteredChartRecordsByJob() {
            this.filteredJobsChartData = this.recordsByJobData.filter(item => {
                const jobNameMatch = this.currentRecordsJobForChart === null || this.currentRecordsJobForChart.length === 0 || this.currentRecordsJobForChart.includes('All') || this.currentRecordsJobForChart.includes(item.job_name);
                return jobNameMatch;
            });
        },
        async executeParallelOperations() {
            this.loading = true;
            try {
                const [usageData, meteredRecords, recordsByJob] = await Promise.all([
                    this.getUsageData(),
                    this.getMeteredRecords(),
                    this.getRecordsByJob(),
                    this.getInfraUsageData(),
                ]);
            
            } catch (error) {            
                console.error('Error:', error);
            } finally {
                this.loading = false;
            } 
        },
        async getUsageData(){
            await this.fetchUsageData("daily");
            await this.fetchUsageData("aggregate"); 

        },
        async getInfraUsageData(){
            this.loading = true
            await this.fetchInfraUsageData("daily");
            await this.fetchInfraUsageData("aggregate");            
            this.loading = false

        },
        handleAllSelection(newValue, propertyName, tabName) {
            if (Array.isArray(newValue) && newValue.length > 1) {
                const allIndex = newValue.indexOf('All');
                if (allIndex !== -1) {
                    if (allIndex === newValue.length - 1) {
                        this[propertyName] = ['All'];
                    } else {
                        this[propertyName] = newValue.filter(item => item !== 'All');
                    }
                }
            }
            if(tabName == "job_records") {
                this.jobRecordsCollapsibleCol="";
                this.filteredRecordsByJob();                
                this.filteredChartRecordsByJob();
            } else if(tabName == "metered_records") {
                this.meterCollapsibleCol="";
                this.filteredMeteredData();
            }
            
        },       
        exportViewCSV() {
            if(this.activeTab=='Records By Job') {
                this.exportJRCurrentView = !this.exportJRCurrentView;
            } else if (this.activeTab=='Job Metered Records') {
                this.exportMRCurrentView = !this.exportMRCurrentView;
            } else if (this.activeTab=='Infra Metered Records') {
                this.exportIMRCurrentView = !this.exportIMRCurrentView;
            }
            
        },
        exportCSV() { 
            let csvContent = []; 
            let title = ""; 
            if(this.activeTab=='Job Metered Records') {
                csvContent = this.filteredData;
                title = 'Job Metered Records';
            } else if( this.activeTab == 'Records By Job')  {
                csvContent = this.filteredJobsData;
                title = 'Records By Job';
            }  else if( this.activeTab == 'Infra Metered Records')  {
                csvContent = this.infraMeteredData;
                title = 'Infra Metered Records';
            }      
            let header = []
            this.error = null
            if (csvContent.length > 0) {
                Object.keys(csvContent[0]).map((value) => header[value] = value)
                csv.exportCSV(title, [...header, ... csvContent])
            } else {
                this.error = "No data to export"
            }
        },

        async usageMonthChange(month) {
            this.$store.dispatch('amdp/setUsageMonth',month);            
            const startDate = getFirstDayOfMonth(month);
            const endDate = getLastDayOfMonth(month);
            this.$store.dispatch('amdp/setUsageDataStartDate',startDate);
            this.$store.dispatch('amdp/setUsageDataEndDate',endDate);
            this.$store.dispatch('admin/setInfraUsageDataStartDate',startDate);
            this.$store.dispatch('admin/setInfraUsageDataEndDate',endDate);
            await this.executeParallelOperations();
            
        },     

        async startDateChange(newValue) { 
            if(newValue !== this.$store.state.amdp.usageDataStartDate ) {
                this.$store.dispatch('amdp/setUsageMonth','custom');   
                this.$store.dispatch('amdp/setUsageDataStartDate',newValue);
                this.$store.dispatch('admin/setInfraUsageDataStartDate',newValue);
                await this.executeParallelOperations();
            }
            
        },
        async endDateChange(newValue) {
            if(newValue !== this.$store.state.amdp.usageDataEndDate ) {
                this.$store.dispatch('amdp/setUsageMonth','custom'); 
                this.$store.dispatch('amdp/setUsageDataEndDate',newValue)  
                this.$store.dispatch('admin/setInfraUsageDataEndDate',newValue);
                await this.executeParallelOperations();
            }
            
        }
    },

    watch: {        
        currentMeterLabel: {
            handler(newValue) {
                this.handleAllSelection(newValue, 'currentMeterLabel', 'metered_records');
            },
            deep: true
        },
        currentMetricType: {
            handler(newValue) {
                this.handleAllSelection(newValue, 'currentMetricType', 'metered_records');
            },
            deep: true          
        },
        currentJob: {
            handler(newValue) {
                this.handleAllSelection(newValue, 'currentJob', 'metered_records');
            },
            deep: true          
        },

        currentRecordsJob: {
            handler(newValue) {
                this.handleAllSelection(newValue, 'currentRecordsJob', 'job_records');
            },
            deep: true
        },
        currentRecordsJobForChart: {
            handler(newValue) {
                this.handleAllSelection(newValue, 'currentRecordsJobForChart', 'job_records');
            },
            deep: true

        },
        currentDataChannel: {
            handler(newValue) {
                this.handleAllSelection(newValue, 'currentDataChannel', 'job_records');
            },
            deep: true
        },
        currentStage: {
            handler(newValue) {
                this.handleAllSelection(newValue, 'currentStage', 'job_records');
            },
            deep: true
        },  
        usageInfraDailyData: {
            handler(newValue) {
                if( newValue){
                    this.infraMeteredData =[]
                    for (const dataPoint of newValue) {
                        const date = dataPoint[this.usageInfraDailyHeaders.indexOf('date')]
                        const dataLabel = dataPoint[this.usageInfraDailyHeaders.indexOf('key')]
                        const value = dataPoint[this.usageInfraDailyHeaders.indexOf('value')]

                        if (dataLabel && (this.infraMeteredLabels.some(substring => dataLabel.includes(substring)))) {
                            const obj = {"date":date, "meter_label":dataLabel, "value":value};
                            this.infraMeteredData.push(obj)
                        }
                    }
                }
            },
            deep: true
        }
    },
    computed: {
        ...mapState({
            startDate: state => state.amdp.usageDataStartDate,
            endDate: state => state.amdp.usageDataEndDate,
            usageMonth: state =>state.amdp.usageMonth,
            cid: (state) => state.currentClient.cid,
            activeTab: (state) => state.activeTab,
            usageDailyData: state => state.amdp.usageData.daily ? state.amdp.usageData.daily.data : [],
            usageDailyHeaders: state => state.amdp.usageData.daily ? state.amdp.usageData.daily.headers : [],
            usageDailyUnits: state => state.amdp.usageData.daily ? state.amdp.usageData.daily.units : {},
            aggregateData: state => state.amdp.usageData.aggregate ? state.amdp.usageData.aggregate.data : [],
            aggregateHeaders: state => state.amdp.usageData.aggregate ? state.amdp.usageData.aggregate.headers : [],
            aggregateUnits: state => state.amdp.usageData.aggregate ? state.amdp.usageData.aggregate.units : {},
            infraMeterLabels: state => state.admin.infraMeterLabels,
            usageInfraDailyData: state => state.admin.infraUsageData.daily ? state.admin.infraUsageData.daily.data : [],
            usageInfraDailyHeaders: state => state.admin.infraUsageData.daily ? state.admin.infraUsageData.daily.headers : [],
            usageInfraDailyUnits: state => state.admin.infraUsageData.daily ? state.admin.infraUsageData.daily.units : {},
            
            aggregateInfraData: state => state.admin.infraUsageData.aggregate ? state.admin.infraUsageData.aggregate.data : [],
            aggregateInfraHeaders: state => state.admin.infraUsageData.aggregate ? state.admin.infraUsageData.aggregate.headers : []
        }),

        dailyChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            
            if (this.usageDailyData) {
                let labels = []
                let dataLabelsObj = {}
                let datasetsObj = {}

                // First pass: collect all unique dates and keys
                for (const dataPoint of this.usageDailyData) {
                    const date = dataPoint[this.usageDailyHeaders.indexOf('date')]
                    const dataLabel = dataPoint[this.usageDailyHeaders.indexOf('key')]
                    const value = dataPoint[this.usageDailyHeaders.indexOf('value')]

                    
                        // Add date to labels if not already present
                        if (!labels.includes(date)) {
                            labels.push(date)
                        }
                        
                        // Mark this dataLabel as existing
                        dataLabelsObj[dataLabel] = true
                        
                        // Initialize dataset object if it doesn't exist
                        if (!datasetsObj[dataLabel]) {
                            datasetsObj[dataLabel] = {}
                        }
                        
                        // Store the value indexed by date
                        datasetsObj[dataLabel][date] = value
                    
                }

                // Sort the labels (dates)
                labels.sort()

                // Convert the datasets object to the required array format
                let datasets = Object.keys(dataLabelsObj).map(dataLabel => {
                    // Create an array of values matching the labels array
                    const data = labels.map(date => {
                        return datasetsObj[dataLabel][date] || 0
                    })

                    return {
                        label: this.usageDailyUnits[dataLabel]?`${dataLabel} (${this.usageDailyUnits[dataLabel]})`:dataLabel,
                        data: data
                    }
                })

                chartData = {
                    labels: labels,
                    datasets: datasets
                }
            }

            return chartData;
        },          

        aggregateChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            if (this.aggregateData) {
                let labels = []
                let dataset = []
                for (const dataPoint of this.aggregateData) {
                    var dataLabel = dataPoint[this.aggregateHeaders.indexOf('key')]
                    const unit = this.aggregateUnits[dataLabel];
                    labels.push(unit ? `${dataLabel} (${unit})` : dataLabel);
                    dataset.push(dataPoint[this.aggregateHeaders.indexOf('value')])
                }

                chartData = {
                    labels: labels,
                    datasets: [{ label: "Count", data: dataset }]
                }
            }

            return chartData
        },

        dailyJobChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            if (this.filteredJobsChartData) {
                let labels = []
                let dataLabelsObj = {}
                let datasetsObj = {}

                // First pass: collect all unique dates and keys
                for (const dataPoint of this.filteredJobsChartData) {
                    const date = dataPoint['date']
                    const dataLabel = dataPoint['job_name']
                    const value = dataPoint['record_count']

                    
                        // Add date to labels if not already present
                        if (!labels.includes(date)) {
                            labels.push(date)
                        }
                        
                        // Mark this dataLabel as existing
                        dataLabelsObj[dataLabel] = true
                        
                        // Initialize dataset object if it doesn't exist
                        if (!datasetsObj[dataLabel]) {
                            datasetsObj[dataLabel] = {}
                        }
                        
                        // Store the value indexed by date
                        datasetsObj[dataLabel][date] = value
                    
                }

                // Sort the labels (dates)
                labels.sort()

                // Convert the datasets object to the required array format
                let datasets = Object.keys(dataLabelsObj).map(dataLabel => {
                    // Create an array of values matching the labels array
                    const data = labels.map(date => {
                        return datasetsObj[dataLabel][date] || 0
                    })

                    return {
                        label: dataLabel,
                        data: data
                    }
                })

                chartData = {
                    labels: labels,
                    datasets: datasets
                }
            }
          
            return chartData
        },
        infraMeteredLabels() {
            return Object.entries(this.infraMeterLabels)
            .filter(([key, value]) => value.isMetered)
            .map(([key, value]) => (value.label));
        },

    },
   

    async beforeMount() {
        await this.executeParallelOperations();
    },


}
</script>

<style scoped>
.chart-view{ 
  height: 40vh !important;  
}
.min-col {
    min-width: 16.666667%;

}
</style>